import React from "react"
import { graphql } from "gatsby"

import Image from "components/utils/Image"
import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import { Title, Label, Paragraph } from "components/atoms/Typography"

const ImageQuote = ({
  sectionStyling,
  quote,
  image,
  author,
  position,
  icon,
  isFirstSection,
}) => {
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={{ _: "column", sm: "row" }}
        >
          <Reveal>
            <Box
              width={"142px"}
              height={"142px"}
              borderRadius={"90px"}
              overflow={"hidden"}
              mr={7}
              mt={6}
            >
              <Image {...image} />
            </Box>
          </Reveal>
          <Reveal>
            <Box maxWidth={25}>
              <Box width={"24px"} mb={6}>
                <Image {...icon} />
              </Box>
              <Title variant={"h3"} mb={4}>
                {quote}
              </Title>
              <Paragraph>{author}</Paragraph>
              <Paragraph>{position}</Paragraph>
            </Box>
          </Reveal>
        </Flex>
      </StandardPadding>
    </Section>
  )
}

export default ImageQuote

export const query = graphql`
  fragment ImageQuote on ContentfulImageAndQuote {
    sectionStyling {
      ...Section
    }
    quote
    author
    position
    image {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    icon {
      fluid(maxWidth: 100, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
