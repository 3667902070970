import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import Box from "components/atoms/Box"
import StandardPadding from "components/atoms/StandardPadding"
import RichText from "components/molecules/RichText"
import ChartCard from "components/molecules/ChartCard"
import SectionTitle from "components/molecules/SectionTitle"

const Text = ({ sectionStyling, sectionTitle, text, isFirstSection }) => {
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        py={8}
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Reveal>
          <SectionTitle {...sectionTitle} mb={5} maxWidth={40} />
          <Box maxWidth={40}>
            <RichText text={text} />
          </Box>
        </Reveal>
      </StandardPadding>
    </Section>
  )
}

export default Text

export const query = graphql`
  fragment Text on ContentfulTextSection {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    text {
      json
    }
  }
`
