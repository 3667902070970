import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import "@reach/dialog/styles.css"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import FlexGrid from "components/atoms/FlexGrid"
// import Flex from "components/atoms/Flex"
// import Button from "components/atoms/Button"

import Filter from "components/molecules/Filter"
import ArticlePreview from "components/molecules/ArticlePreview"

const NUM_TO_LOAD = 12

const ArticleGrid = ({
  sectionStyling,
  items,
  itemsBackgroundColor,
  tags,
  isFirstSection,
  ...rest
}) => {
  const [listItems, setListItems] = useState(
    Array.from(Array(12).keys(), n => n + 1)
  )
  const [isFetching, setIsFetching] = useState(false)
  const [filter, setFilter] = useState("all")
  const [filteredItems, setFilteredItems] = useState(items)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [filter])

  useEffect(() => {
    if (!isFetching) return
    fetchMoreListItems()
  }, [isFetching])

  useEffect(() => {
    setFilteredItems(
      items.filter(item => {
        if (filter === "all") return items
        else if (filter !== "all") {
          const tagsArr = item.tags?.map(tag => tag.tag.toLowerCase())
          if (tagsArr?.includes(filter)) {
            return item
          }
        }
      })
    )
  }, [filter])

  function handleScroll() {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 600
    ) {
      setIsFetching(true)
    }
    return
  }

  function fetchMoreListItems() {
    if (allLoaded) {
      setIsFetching(false)
      return
    }
    setTimeout(() => {
      setListItems(prevState => [
        ...prevState,
        ...Array.from(Array(NUM_TO_LOAD).keys(), n => n + prevState.length + 1),
      ])
      setIsFetching(false)
    }, 300)
  }

  const allLoaded = listItems.length >= filteredItems.length

  let selectionValues = ["All"]
  let selections = [{ value: "all", label: "All" }]

  if (tags !== null && tags !== undefined) {
    tags.map(tag => {
      if (!selectionValues.includes(tag)) {
        selectionValues.push(tag.tag)
        selections.push({
          value: tag.tag.toLowerCase(),
          label: tag.tag,
        })
      }
    })
  }

  return (
    <>
      <Section
        {...sectionStyling}
        mt={isFirstSection && "-100px"}
        pt={isFirstSection && "100px"}
      >
        <StandardPadding
          pt={sectionStyling?.paddingTop}
          pb={sectionStyling?.paddingBottom}
        >
          <Reveal>
            <Filter
              selections={selections}
              onChange={val => {
                setFilter(val)
                setListItems(Array.from(Array(12).keys(), n => n + 1))
              }}
              value={filter}
              mb={6}
              linkVariant={itemsBackgroundColor === "white" && "inverted"}
            />
          </Reveal>
          <FlexGrid columns={{ _: 1, md: 2, lg: 3 }} gutter={5}>
            {filteredItems.slice(0, listItems.length).map((item, idx) => (
              <Reveal key={idx}>
                <ArticlePreview
                  {...rest}
                  {...item}
                  tags={[]}
                  key={item.slug}
                  variant={itemsBackgroundColor}
                />
              </Reveal>
            ))}
          </FlexGrid>
          {/* {!allLoaded && (
            <Reveal>
              <Flex mt={8} flexDirection="column" alignItems="center">
                <Button
                  showArrow={false}
                  text="Load More"
                  onClick={() => setNumLoaded(numLoaded + NUM_TO_LOAD)}
                  variant={itemsBackgroundColor === "white" ? "filled" : "link"}
                />
              </Flex>
            </Reveal>
          )} */}
        </StandardPadding>
      </Section>
    </>
  )
}

export default ArticleGrid

export const query = graphql`
  fragment ArticleGrid on ContentfulArticleGrid {
    sectionStyling: section {
      ...Section
    }
    tags {
      tag
    }
    itemsBackgroundColor
    items {
      ... on ContentfulArticle {
        __typename
        title
        label
        date
        slug
        articleSource
        tags {
          tag
        }
        location
        description
        image {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulArticleExternalLink {
        __typename
        date
        description
        articleSource
        label
        title
        tags {
          tag
        }
        href
        image {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulVideo {
        __typename
        title
        date
        source
        description
        image {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        tags {
          tag
        }
        html {
          html
        }
        foxBusinessVideoId
      }
    }
  }
`
