import React from "react"
import { graphql } from "gatsby"

import Layout from "components/page/Layout"
import SEO from "components/page/Seo"
import Sections from "components/page/Sections"

import SectionBreakBottom from "images/section-break-bottom.svg"
import SectionBreakBottomMobile from "images/section-break-bottom-mobile.svg"

const Page = ({ data }) => {
  const site = data.contentfulSite
  const page = data.contentfulPage

  return (
    <Layout
      site={site}
      headHtml={page.headHtml?.headHtml}
      bodyHtml={page.bodyHtml?.bodyHtml}
      firstSectionTheme={page.sections[0].sectionStyling.theme}
      pageSlug={page.slug}
    >
      <SEO
        // favicon={site.favicon}
        title={page.titleTag || page.title}
        metaDescription={page.metaDescription || site.metaDescription}
        image={page.searchImage?.file?.url || site.socialShareImage?.file?.url}
        noIndex={page.noIndex}
      />
      {page.sections.map((section, i) => {
        let currentSection = page.sections[i].section
        if (!currentSection) currentSection = {}

        // Correct z-indexing for wavy backgrounds
        currentSection.zIndex = page.sections.length - i - 1

        // Tighten up hero section spacing
        if (i === 0 && section.__typename === "ContentfulImageText") {
          currentSection.mt = -6
        }

        if (i === 0) {
          page.sections[i].isFirstSection = true
          //console.log("section 0: ", page.sections[i])
        }

        // h1 vs h2
        let sectionTitle = page.sections[i].sectionTitle
        if (sectionTitle) {
          if (i === 0) {
            sectionTitle.as = "h1"
          } else {
            sectionTitle.as = "h2"
          }
        }

        // Add padding to section under a section w wavy bottom
        if (i > 0) {
          const previousSection = page.sections[i - 1].section
          if (previousSection?.wavyBottom) {
            currentSection.pt = 8
          }
        }
        //add pop-up disclaimer to sections for link usage
        currentSection.disclosureText = site.disclosureText
        page.sections[i].section = currentSection
        return <Sections section={section} key={i} />
      })}
      <SectionBreakBottom />
      <SectionBreakBottomMobile />
    </Layout>
  )
}

export default Page

export const query = graphql`
  fragment Page on ContentfulPage {
    title
    slug
    titleTag
    metaDescription
    searchImage {
      file {
        url
      }
    }
    noIndex
    headHtml {
      headHtml
    }
    bodyHtml {
      bodyHtml
    }
    sections {
      ... on ContentfulImageText {
        ...ImageText
      }
      ... on ContentfulCta {
        ...Cta
      }
      ... on ContentfulImageAndCards {
        ...ImageCards
      }
      ... on ContentfulRegions {
        ...Regions
      }
      ... on ContentfulTextSection {
        ...Text
      }
      ... on ContentfulImageBlock {
        ...ImageBlock
      }
      ... on ContentfulInfoCards {
        ...InfoCards
      }
      ... on ContentfulTitleAndPosts {
        ...TitleArticles
      }
      ... on ContentfulArticlePreview {
        ...RecentArticles
      }
      ... on ContentfulImageAndQuote {
        ...ImageQuote
      }
      ... on ContentfulArticleGrid {
        ...ArticleGrid
      }
      ... on ContentfulQuoteCarousel {
        ...QuoteCarousel
      }
      ... on ContentfulFeaturedMediaCarousel {
        ...FeaturedMediaCarousel
      }
      ... on ContentfulInfoCardsAndRichText {
        ...InfoCardsAndRichText
      }
      ... on ContentfulSubsectionSelector {
        ...SubsectionSelector
      }
      ... on ContentfulChartCards {
        ...ChartCards
      }
      ... on ContentfulStats {
        ...Stats
      }
      ... on ContentfulRichTextCards {
        ...RichTextCards
      }
      ... on ContentfulTeamGrid {
        ...TeamGrid
      }
      ... on ContentfulContact {
        ...Contact
      }
      ... on ContentfulImageTextGroup {
        ...ImageTextGroup
      }
      ... on ContentfulVideoSection {
        ...Video
      }
    }
  }
`
// removed due to Twitter API update
// ... on ContentfulTwitterMediaSection {
//   ...TwitterMedia
// }
