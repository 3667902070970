import React from "react"
import { graphql } from "gatsby"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"

import ImageText from "components/sections/ImageText"

const ImageTextGroup = ({
  sectionStyling,
  imageTextSections,
  isFirstSection,
  ...rest
}) => {
  const disclosureText = rest?.section?.disclosureText
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        px={null}
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        {imageTextSections &&
          imageTextSections.map((section, idx) => {
            return (
              <ImageText
                disclosureText={disclosureText}
                {...section}
                sectionStyling={{
                  bg: "transparent",
                  paddingTop: "xs",
                  paddingBottom: "xs",
                }}
              />
            )
          })}
      </StandardPadding>
    </Section>
  )
}

export default ImageTextGroup

export const query = graphql`
  fragment ImageTextGroup on ContentfulImageTextGroup {
    sectionStyling {
      ...Section
    }
    imageTextSections {
      ...ImageText
    }
  }
`
