import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Reveal from "components/utils/Reveal"
import Image from "components/utils/Image"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, { Pagination, Autoplay } from "swiper/core"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import { Text, Paragraph, Label } from "components/atoms/Typography"

import SectionTitle from "components/molecules/SectionTitle"
import VideoDialog from "components/molecules/VideoDialog"
import Link from "components/utils/Link"

const StyledFlex = styled(Flex)`
  transition: opacity 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

SwiperCore.use([Autoplay, Pagination])

const StyledTextBox = styled(Box)`
  font-size: 10px;
  line-height: 1.4;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 34px;
`

const TitleArticles = ({
  sectionStyling,
  sectionTitle,
  button,
  articleLabel,
  articles,
  isFirstSection,
  carouselItems,
  foxBusinessVideoId,
  html,
  ...rest
}) => {
  const standardPaddingRef = useRef(null)
  const [swiper, setSwiper] = useState(null)
  const [activeIdx, setActiveIdx] = useState(0)
  const [showDialog, setShowDialog] = useState(null)
  const disclosureText = rest?.section?.disclosureText

  return (
    <Section
      {...sectionStyling}
      backgroundImageDesktop={null}
      mt={isFirstSection && "-100px"}
    >
      <Swiper
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          bottom: "0px",
          zIndex: 0,
        }}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        preloadImages={true}
        slidesPerView={1}
        spaceBetween={0}
        centeredSlides={false}
        initialSlide={0}
        onSwiper={setSwiper}
        onSlideChange={swiper => {
          setActiveIdx(swiper.realIndex) //realIndex uses index 0
        }}
      >
        {carouselItems.map((item, i) => {
          return (
            <SwiperSlide>
              <Section
                backgroundImageDesktop={item.backgroundImage}
                width={"100vw"}
                height={"100%"}
              ></Section>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Box
        width={"100vw"}
        borderRight={"100vw solid white"}
        borderTop={{
          _: "80px solid transparent",
          sm: "130px solid transparent",
          lg: "120px solid transparent",
        }}
        borderBottom={"5px solid white"}
        position={"absolute"}
        zIndex={0}
        bottom={"0px"}
      ></Box>
      <StandardPadding
        py={null}
        pt={11}
        pb={8}
        ref={standardPaddingRef}
        zIndex={10}
      >
        <Flex
          flexDirection={{ _: "column", md: "row" }}
          justifyContent={{ _: "center", md: "space-between" }}
          mt={5}
          zIndex={10}
        >
          <Reveal>
            <Box
              mb={{ _: 4, md: 0 }}
              zIndex={10}
              minHeight={"380px"}
              position={"relative"}
            >
              <SectionTitle
                {...carouselItems[activeIdx].sectionTitle}
                maxWidth={25}
                mt={2}
                mb={5}
              />
              <Button {...carouselItems[activeIdx].button} variant={"white"} />
              {/* indicators */}
              <Flex mt={5} position={"absolute"} bottom={"30px"}>
                {carouselItems.map((indicator, idx) => {
                  return (
                    <Flex
                      height={"10px"}
                      width={"32px"}
                      mr={"11px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        swiper.slideTo(idx + 1) // swiper starts at index 1
                        setActiveIdx(idx)
                      }}
                    >
                      <Box
                        height={"3px"}
                        width={"32px"}
                        bg={
                          activeIdx === idx
                            ? "rgba(255,255,255,1)"
                            : "rgba(255,255,255,0.3)"
                        }
                      ></Box>
                    </Flex>
                  )
                })}
              </Flex>
            </Box>
          </Reveal>

          <Box>
            <Reveal>
              <Box mb={4} zIndex={10}>
                <Text fontSize={"18px"} color={"white"}>
                  {articleLabel}
                </Text>
              </Box>
            </Reveal>
            <Reveal>
              {articles.map((article, idx) => {
                const linkContent = (
                  <StyledFlex
                    maxWidth={25}
                    minWidth={{ lg: 25 }}
                    //maxHeight={"120px"}
                    bg={"white"}
                    alignItems={"flex-start"}
                    boxShadow={"md"}
                    p={3}
                    mb={4}
                    className={"article-link"}
                    zIndex={10}
                    position={"relative"}
                  >
                    <Flex height={"100%"} alignItems={"center"}>
                      <Box width={"122px"} mr={4} overflow={"hidden"}>
                        <Image {...article.image} />
                      </Box>
                    </Flex>
                    <Box maxWidth={"220px"}>
                      <Label fontSize={"10px"} color={"primary"}>
                        {article.__typename === "ContentfulVideo"
                          ? "Video"
                          : article.label}
                      </Label>
                      <Paragraph mt={1} color={"black"}>
                        {article.title}
                      </Paragraph>
                    </Box>
                  </StyledFlex>
                )
                return (
                  <>
                    {article.__typename === "ContentfulArticleExternalLink" && (
                      <Link disclosureText={disclosureText} href={article.href}>
                        {linkContent}
                      </Link>
                    )}
                    {article.__typename === "ContentfulArticle" && (
                      <Link to={article}>{linkContent}</Link>
                    )}
                    {article.__typename === "ContentfulVideo" && (
                      <>
                        <Box onClick={() => setShowDialog(idx)}>
                          {linkContent}
                        </Box>
                        <VideoDialog
                          close={() => setShowDialog(null)}
                          show={showDialog === idx}
                          html={article?.html}
                          foxBusinessVideoId={article?.foxBusinessVideoId}
                        />
                      </>
                    )}
                  </>
                )
              })}
            </Reveal>
          </Box>
        </Flex>
      </StandardPadding>
    </Section>
  )
}

export default TitleArticles

export const query = graphql`
  fragment TitleArticles on ContentfulTitleAndPosts {
    sectionStyling {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    articleLabel
    carouselItems {
      sectionTitle {
        ...SectionTitle
      }
      backgroundImage {
        fluid(maxWidth: 2800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      button {
        ...Button
      }
    }
    articles {
      ... on ContentfulArticle {
        __typename
        label
        title
        description
        slug
        location
        image {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulArticleExternalLink {
        __typename
        date
        description
        label
        title
        href
        image {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulVideo {
        __typename
        title
        date
        source
        description
        image {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        tags {
          tag
        }
        html {
          html
        }
        foxBusinessVideoId
      }
    }
  }
`
