import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Image from "components/utils/Image"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import FlexGrid from "components/atoms/FlexGrid"
import { Title, Label } from "components/atoms/Typography"

import SectionTitle from "components/molecules/SectionTitle"

const Stats = ({
  sectionTitle,
  sectionStyling,
  logo,
  stats,
  isFirstSection,
}) => {
  return (
    <Section {...sectionStyling}>
      <StandardPadding
        py={{ _: 5, md: 6 }}
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
        px={{ _: 6, md: 0 }}
        maxWidth={46}
      >
        <SectionTitle
          {...sectionTitle}
          mb={7}
          mt={isFirstSection && "-100px"}
          pt={isFirstSection && "100px"}
        />
        {/* <Flex
          alignItems={{ _: "flex-start", md: "center" }}
          flexDirection={{ _: "column", md: "row" }}
        >
          <Box width={9} mr={{ _: 0, md: 8 }} mb={{ _: 6, md: 0 }}>
            <Image {...logo} />
          </Box> */}
        {/* <FlexGrid
            //justifyContent={"space-between"}
            gutter={{ _: 6, md: 8 }}
            columns={{ _: 2, md: "auto" }}
          > */}
        <Flex
          justifyContent={"space-around"}
          width={"100%"}
          //border={"1px solid red"}
        >
          {stats?.map(stat => {
            return (
              <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Title as="span">{stat.value}</Title>
                <Label>{stat.title}</Label>
              </Flex>
            )
          })}
        </Flex>
        {/* </FlexGrid> */}
        {/* </Flex> */}
      </StandardPadding>
    </Section>
  )
}

export default Stats

Stats.propTypes = {
  section: PropTypes.shape(Section.strapiProps).isRequired,
  logo: PropTypes.object,
  stats: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, value: PropTypes.string })
  ),
}

export const query = graphql`
  fragment Stats on ContentfulStats {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    stats {
      title
      value
    }
  }
`
