import React, { useState } from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Button from "components/atoms/Button"

import SectionTitle from "components/molecules/SectionTitle"
import RichText from "components/molecules/RichText"

import Sections from "components/page/Sections"

const SubsectionSelector = ({
  sectionStyling,
  sectionTitle,
  subSections,
  textArea,
  isFirstSection,
}) => {
  const [currentSelection, setCurrentSelection] = useState(0)

  let sections = subSections[currentSelection].sections

  return (
    <>
      <Section
        {...sectionStyling}
        mt={isFirstSection && "-100px"}
        pt={isFirstSection && "100px"}
      >
        <StandardPadding
          pt={sectionStyling.paddingTop}
          pb={sectionStyling.paddingBottom}
        >
          <Reveal>
            <Flex
              flexDirection={{ _: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <SectionTitle
                {...sectionTitle}
                maxWidth={{ _: 24, lg: 32 }}
                mr={5}
                mb={4}
              />
              <Flex
                mt={{ _: 2, md: 6 }}
                pl={4}
                maxWidth={"350px"}
                borderLeft={"2px solid #CECFD2"}
                height={"100%"}
              >
                <RichText text={textArea} />
              </Flex>
            </Flex>
            <Flex
              mt={6}
              maxWidth={{ _: 20, sm: "100%" }}
              flexWrap={{ _: "wrap", sm: "no-wrap" }}
            >
              {subSections.map((subSection, idx) => (
                <Button
                  ml={idx ? "12px" : 0}
                  text={subSection.title}
                  variant={currentSelection === idx ? "primary" : "inverted"}
                  onClick={() => setCurrentSelection(idx)}
                  mb={{ _: 4, md: 0 }}
                />
              ))}
            </Flex>
          </Reveal>
        </StandardPadding>
      </Section>
      {sections !== null && sections !== undefined && (
        <>
          {sections.map((section, i) => (
            <Sections section={section} key={i} />
          ))}
        </>
      )}
    </>
  )
}

export default SubsectionSelector

export const query = graphql`
  fragment SubsectionSelector on ContentfulSubsectionSelector {
    sectionStyling {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    textArea {
      json
    }
    subSections {
      categoryName
      title
      sections {
        ... on ContentfulChartCards {
          ...ChartCards
        }
        ... on ContentfulTextSection {
          ...Text
        }
        ... on ContentfulImageText {
          ...ImageText
        }
        ... on ContentfulInfoCardsAndRichText {
          ...InfoCardsAndRichText
        }
        ... on ContentfulInfoCards {
          ...InfoCards
        }
      }
    }
  }
`
