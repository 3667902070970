// https://github.com/rebassjs/grid
import styled from "styled-components"
import {
  space,
  color,
  layout,
  flexbox,
  border,
  shadow,
  position,
  typography,
  compose,
} from "styled-system"
import propTypes from "@styled-system/prop-types"
import { shouldForwardProp } from "lib/styled-system/helpers.js"

const StyledElem = elem => {
  return styled(elem).withConfig({ shouldForwardProp })(
    compose(space, color, layout, flexbox, border, shadow, position, typography)
  )
}

StyledElem.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.flexbox,
  ...propTypes.border,
  ...propTypes.shadow,
  ...propTypes.position,
  ...propTypes.typography,
}

export default StyledElem
