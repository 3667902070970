import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Link from "components/utils/Link"

import Card from "components/atoms/Card"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { Title, Paragraph, Label } from "components/atoms/Typography"
import Button from "components/atoms/Button"
import Icon from "components/atoms/Icon"

import LargeArrow from "components/icons/large-arrow.svg"
import SmallArrow from "components/icons/SmallArrow"

import { colors } from "lib/styled-system/constants"
import { NonceProvider } from "react-select"

const DivWrapper = ({ children }) => {
  return <Box maxWidth="calc(100% - 64px)">{children}</Box>
}

const StyledLink = styled(Link)`
  & .clickable-card {
    transition: opacity 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`

const BlueStyledLink = styled(Link)`
  & .clickable-card {
    transition: background 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
    &:hover {
      cursor: pointer;
      background: #4163f4;
    }
  }
`

const TextContent = ({
  icon,
  title,
  paragraph,
  tag,
  tagLocation,
  button,
  center,
  cardLink,
  active,
  cardStyle,
}) => {
  // let titleWords = title !== null && title.split(" ")
  // let firstWords = ""
  // let lastWord
  // if (title !== null && titleWords.length > 1) {
  //   lastWord = titleWords.pop()
  //   titleWords.forEach(word => (firstWords = firstWords + word + " "))
  // }
  return (
    <>
      {!cardLink && title && (
        <>
          {tag && tagLocation === "top" && (
            <Label
              color={"rgba(0,0,0,0.5)"}
              textAlign={center ? "center" : null}
              mb={2}
            >
              {tag}
            </Label>
          )}
          <Title
            variant="h3"
            mb={tagLocation === "top" ? 0 : 4}
            color={
              cardStyle === "border-top-blue" ||
              cardStyle === "default-blue-title"
                ? "primary"
                : "black"
            }
          >
            {title}
          </Title>
        </>
      )}
      {cardLink && title && (
        <>
          {tag && tagLocation === "top" && (
            <Label
              color={"rgba(0,0,0,0.5)"}
              textAlign={center ? "center" : null}
              mb={2}
            >
              {tag}
            </Label>
          )}
          {cardStyle !== "hover-blue-card" ? (
            <Flex
              width="100%"
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Title variant="h3" color={"primary"} mb={3}>
                {title}
              </Title>
              <Box
                ml={3}
                mb={tagLocation === "top" ? 0 : 4}
                style={{
                  transition: "transform 0.2s ease",
                  transform: active ? "translateX(5px)" : "translateX(0px)",
                }}
              >
                <LargeArrow />
              </Box>
            </Flex>
          ) : (
            <Flex width="100%" flexDirection={"row"} justifyContent={"center"}>
              <Title
                variant="h3"
                color={active === true ? "white" : "primary"}
                mb={4}
                fontSize="1.5em"
              >
                {title}
              </Title>
              <Box
                ml={3}
                mb={tagLocation === "top" ? 0 : 4}
                style={{
                  transition: "transform 0.2s ease",
                  transform: active ? "translateX(5px)" : "translateX(0px)",
                }}
              ></Box>
            </Flex>
          )}
        </>
      )}
      {cardStyle !== "hover-blue-card"
        ? paragraph && (
            <Paragraph
              width="90%"
              mb={tag ? 6 : 0}
              textAlign={
                center || cardStyle === "hover-blue-card" ? "center" : null
              }
              color={
                cardStyle === "hover-blue-card" && active === true
                  ? "white"
                  : "black"
              }
            >
              {paragraph}
            </Paragraph>
          )
        : paragraph && (
            <Paragraph
              width="90%"
              mb={tag ? 6 : 0}
              textAlign={
                center || cardStyle === "hover-blue-card" ? "center" : null
              }
              color={
                cardStyle === "hover-blue-card" && active === true
                  ? "white"
                  : "black"
              }
              fontSize="1em"
              px="10px"
            >
              {paragraph}
            </Paragraph>
          )}
      {tag && tagLocation !== "top" && (
        <Label color={"rgba(0,0,0,0.5)"} textAlign={center ? "center" : null}>
          {tag}
        </Label>
      )}
      {button && !cardLink && <Button {...button} mt={6} />}
    </>
  )
}

const LinkWrapper = ({ cardLink, children, media, cardStyle, ...rest }) => {
  const disclosureText = rest?.section?.disclosureText
  if (cardLink && cardStyle === "hover-blue-card") {
    return (
      <BlueStyledLink disclosureText={disclosureText} {...cardLink}>
        {children}
      </BlueStyledLink>
    )
  }
  if (cardLink && cardStyle !== "hover-blue-card") {
    return (
      <StyledLink disclosureText={disclosureText} {...cardLink}>
        {children}
      </StyledLink>
    )
  }
  if (media) {
    const mediaUrl = "https:" + media.file.url
    return (
      <StyledLink disclosureText={disclosureText} href={mediaUrl}>
        {children}
      </StyledLink>
    )
  }
  return <>{children}</>
}

const InfoCard = ({
  title,
  paragraph,
  tag,
  tagLocation,
  icon,
  align,
  button,
  bg,
  boxShadow,
  iconSize,
  cardLink,
  cardStyle = "default",
  iconPosition,
  listItems,
  maxWidth,
  media,
  borderRadius,
  ...rest
}) => {
  const [hoverActive, setHoverActive] = useState(false)
  const center = align === "center"

  let borderLeftStyle
  let borderTopStyle
  switch (cardStyle) {
    case "border-left-grey":
      borderLeftStyle = "2px solid rgba(65, 99, 244, .2)"
      borderTopStyle = "none"
      break
    case "border-left-blue":
      borderLeftStyle = "2px solid #4163F4"
      borderTopStyle = "none"
      break
    case "border-top-blue":
      borderLeftStyle = "none"
      borderTopStyle = "6px solid #4163F4"
      break
    case "hover-blue-card":
      borderLeftStyle = "none"
      borderTopStyle = "6px solid #4163F4"
      break
    default:
      borderLeftStyle = ""
      borderTopStyle = ""
  }

  let direction
  switch (cardStyle) {
    case "left-icon":
      direction = "row-reverse"
      break
    case "border-left-grey":
      direction = "column-reverse"
      break
    case "border-left-blue":
      direction = "column-reverse"
      break
    case "border-top-blue":
      direction = "row"
      break
    default:
      direction = "row"
  }
  if (iconPosition === "top") {
    direction = "column-reverse"
  }

  return (
    <LinkWrapper
      {...rest}
      media={media}
      cardLink={cardLink}
      cardStyle={cardStyle}
    >
      <Card
        className={media || cardLink ? "clickable-card" : ""}
        onMouseEnter={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
        px={bg && 4}
        pt={bg && 5}
        pb={bg && 4}
        height={cardStyle === "hover-blue-card" ? 12 : "100%"}
        //minHeight={"145px"}
        bg={bg}
        //bg={bg ? bg : undefined} // send undefined so the default props are used
        maxWidth={maxWidth ? maxWidth : !bg ? 44 : "584px"}
        display="flex"
        alignItems={center ? "center" : "left"}
        textAlign={center ? "center" : "left"}
        boxShadow={boxShadow}
        borderLeft={borderLeftStyle}
        borderTop={borderTopStyle}
        borderRadius={
          borderRadius ? borderRadius : cardStyle === "default-blue-title" && 10
        }
      >
        {icon && (
          <Flex
            width={"100%"}
            justifyContent={
              cardStyle === "border-left-grey" ||
              cardStyle === "border-left-blue"
                ? "flex-end"
                : "space-between"
            }
            flexDirection={direction}
            pl={
              cardStyle === "border-left-grey" ||
              cardStyle === "border-left-blue"
                ? 4
                : 0
            }
          >
            <Flex width="100%" flexDirection={"column"}>
              <TextContent
                icon={icon}
                title={title}
                paragraph={paragraph}
                tag={tag}
                tagLocation={tagLocation}
                button={button}
                center={center}
                cardLink={cardLink}
                active={hoverActive}
                cardStyle={cardStyle}
              />
            </Flex>
            <Flex
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              mr={cardStyle === "left-icon" && 4}
              pb={iconPosition === "top" && 5}
            >
              <Icon
                {...icon}
                size={
                  iconSize === "medium"
                    ? "medium"
                    : cardStyle === "left-icon" ||
                      cardStyle === "border-left-blue" ||
                      cardStyle === "border-left-grey"
                    ? "small"
                    : "xs"
                }
              />
            </Flex>
          </Flex>
        )}
        {!icon && (
          <Flex
            width="100%"
            justifyContent={
              cardStyle === "left-border-grey" ? "flex-start" : "space-between"
            }
            alignItems={
              cardStyle === "hover-blue-card" ? "center" : "space-between"
            }
            flexDirection={direction}
            pl={cardStyle === "left-border-grey" ? 4 : 0}
            width={"100%"}
            // border={"1px solid green"}
          >
            <Flex
              width="100%"
              flexDirection="column"
              pl={
                cardStyle === "left-border-grey" ||
                cardStyle === "left-icon" ||
                cardStyle === "hover-blue-card" ||
                listItems
                  ? 0
                  : 4
              }
              // border={"1px solid lightgreen"}
              alignItems={
                cardStyle === "hover-blue-card" ? "center" : "space-between"
              }
              mt={0}
              mb={cardStyle === "hover-blue-card" ? 5 : 0}
            >
              <TextContent
                icon={icon}
                title={title !== null ? title : null}
                paragraph={paragraph}
                tag={tag}
                tagLocation={tagLocation}
                button={button}
                center={center}
                cardLink={cardLink}
                active={hoverActive}
                cardStyle={cardStyle}
              />
              {listItems && (
                <Box>
                  {listItems.map(item => {
                    let iconFlip = null
                    if (item.iconOrientation === "inverted") {
                      iconFlip = { transform: "rotate(180deg)" }
                    }
                    return (
                      <Flex flexDirection={"row"}>
                        <Box mr={3}>
                          <Icon
                            {...item.icon}
                            iconOrientation={item.iconOrientation}
                            style={iconFlip}
                            size={1}
                          />
                        </Box>
                        <Box>
                          <Paragraph mb={3}>{item.text}</Paragraph>
                        </Box>
                      </Flex>
                    )
                  })}
                </Box>
              )}
            </Flex>
          </Flex>
        )}
      </Card>
    </LinkWrapper>
  )
}

export default InfoCard

InfoCard.strapiProps = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  icon: PropTypes.shape(Icon.strapiProps),
  button: PropTypes.shape(Button.strapiProps),
}

InfoCard.propTypes = {
  ...InfoCard.strapiProps,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
  bg: PropTypes.string,
  boxShadow: PropTypes.string,
}

export const query = graphql`
  fragment InfoCard on ContentfulInfoCard {
    title
    paragraph
    tag
    tagLocation
    cardLink {
      ...Link
    }
    icon {
      ...Icon
    }
    button {
      ...Button
    }
    media {
      file {
        url
      }
    }
    listItems {
      iconOrientation
      text
      icon {
        ...Icon
      }
    }
  }
`
