import { useState, useEffect, useContext } from "react"
import { ThemeContext } from "styled-components"

/**
 * UseBreakpoint Hook
 * @param {String} key "sm", "md", "lg" or "xl"
 *
 * @returns {Boolean} true if screen width is <= to the breakpoint, false otherwise
 */
export default key => {
  const isClient = typeof window === "object"
  const { breakpoints } = useContext(ThemeContext)
  const breakpoint = breakpoints[key]

  function isUnderBreakpoint() {
    if (isClient) {
      return !window.matchMedia(`(min-width: ${breakpoint})`).matches
    } else {
      return false
    }
  }

  const [isBreakpoint, setIsBreakpoint] = useState(isUnderBreakpoint)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setIsBreakpoint(isUnderBreakpoint())
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient])

  return isBreakpoint
}
