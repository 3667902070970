import React from "react"
import { graphql } from "gatsby"

import Image from "components/utils/Image"
import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"

const ImageBlock = ({
  sectionStyling,
  image,
  imageHeight,
  gradient,
  isFirstSection,
}) => {
  return (
    <Section
      {...sectionStyling}
      backgroundImageDesktop={gradient === false && null}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding py={0}>
        <Reveal>
          <Image imgStyle={{ width: "100%", height: "auto" }} {...image} />
        </Reveal>
      </StandardPadding>
    </Section>
  )
}

export default ImageBlock

export const query = graphql`
  fragment ImageBlock on ContentfulImageBlock {
    entryTitle
    sectionStyling {
      ...Section
    }
    imageHeight
    gradient
    image {
      description
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
