import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, { Pagination } from "swiper/core"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import Button from "components/atoms/Button"
import Indicator from "components/atoms/Indicator"
import CardCarousel from "components/molecules/CardCarousel"

import SectionTitle from "components/molecules/SectionTitle"
import ArticlePreview from "components/molecules/ArticlePreview"
import NewsletterSignup from "components/molecules/NewsletterSignup"

//install Swiper modules
SwiperCore.use([Pagination])

const StyledSwiper = styled(Swiper)`
  width: 320px;
  overflow: visible;
  margin-left: 0px;
  margin-bottom: 28px;
  .swiper-pagination {
    transform: translateY(40px);
    display: flex;
    justify-content: flex-end;
  }
  .swiper-pagination-bullet {
    background-color: black;
    width: 32px;
    height: 2px;
    border-radius: 0px;
  }
  .swiper-pagination-bullet-active {
    height: 3px;
  }
`

const FeaturedMediaCarousel = props => {
  const {
    sectionStyling,
    sectionTitle,
    items,
    button,
    contentType,
    carouselType,
    includeNewsletterSignup,
    newsletterSignupTitle,
    newsletterSignupDescription,
    isFirstSection,
    itemsBackgroundColor,
    ...rest
  } = props
  const [swiper, setSwiper] = useState(null)
  const [activeIdx, setActiveIdx] = useState(0)

  let indicatorColor = "0,0,0,"
  if (sectionStyling.theme === "dark") {
    indicatorColor = "255,255,255,"
  }

  const pagination = {
    clickable: true,
    type: "bullets",
  }

  if (carouselType === "card") {
    return <CardCarousel {...props} />
  }

  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} labelSizeVariant={"h3"} />
        </Reveal>
        <Reveal>
          <StyledSwiper
            loop={false}
            slidesPerView={1}
            spaceBetween={15}
            centeredSlides={false}
            initialSlide={0}
            onSwiper={setSwiper}
            onSlideChange={swiper => setActiveIdx(swiper.activeIndex)}
          >
            {items.map(item => (
              <SwiperSlide>
                <ArticlePreview
                  {...rest}
                  {...item}
                  variant={
                    (sectionStyling.theme === "light" && "dark") ||
                    (sectionStyling.theme === "dark" && "whiteCard")
                  }
                  //variant={itemsBackgroundColor}
                  contentType={item.__typename}
                />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Reveal>
        <Reveal>
          <Flex
            justifyContent={{ _: "flex-end", sm: "space-between" }}
            flexDirection={{ _: "column-reverse", sm: "row" }}
          >
            {button && (
              <Button
                {...button}
                variant={button.variant || "inverted"}
                mt={{ _: 4, sm: 1 }}
              />
            )}
            <Flex flexDirection={"row"}>
              {items.map((_item, idx) => (
                <Flex
                  height={"10px"}
                  width={"32px"}
                  mr={"11px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    swiper.slideTo(idx)
                    setActiveIdx(idx)
                  }}
                >
                  <Box
                    height={"3px"}
                    width={"32px"}
                    bg={
                      activeIdx === idx
                        ? "rgba(" + indicatorColor + "1)"
                        : "rgba(" + indicatorColor + ".3)"
                    }
                  ></Box>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Reveal>
        {includeNewsletterSignup && (
          <NewsletterSignup
            mt={5}
            title={newsletterSignupTitle}
            description={newsletterSignupDescription}
          />
        )}
      </StandardPadding>
    </Section>
  )
}

export default FeaturedMediaCarousel

export const query = graphql`
  fragment FeaturedMediaCarousel on ContentfulFeaturedMediaCarousel {
    sectionStyling {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    items {
      ... on ContentfulArticle {
        __typename
        id
        date
        title
        description
        slug
        location
        text {
          json
          text
        }
        label
        image {
          description
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulArticleExternalLink {
        __typename
        date
        description
        label
        title
        href
        image {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulVideo {
        __typename
        date
        source
        title
        description
        image {
          description
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        html {
          html
        }
        foxBusinessVideoId
      }
    }
    button {
      ...Button
    }
    itemsBackgroundColor
    carouselType
    includeNewsletterSignup
    newsletterSignupTitle
    newsletterSignupDescription
  }
`
