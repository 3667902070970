import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import { Chart, registerables } from "chart.js"
import Papa from "papaparse"

import { Title } from "components/atoms/Typography"

import Box from "components/atoms/Box"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import RichText from "components/molecules/RichText"
import Link from "components/utils/Link"

const labelColors = [
  "#03045e",
  "#023E8A",
  "#0077B6",
  "#0096C7",
  "#00B4D8",
  "#48CAE4",
  "#90E0EF",
  "#ADE8F4",
  "#CAF0F8",
  "#CFE1FA",
  "#CFE1FA",
]

// const chartData = {
//   labels: [
//     "U.S. Equities",
//     "Non-U.S. Equities",
//     "U.S. Fixed Income",
//     "Non-U.S. Fixed Income",
//     "Cash",
//     "Commodity",
//   ],
//   datasets: [
//     {
//       label: "%",
//       backgroundColor: labelColors,
//       data: [16.7, 6.5, 63.6, 7.3, 4.2, 1.5],
//     },
//   ],
// }

const ChartCard = ({ cardTitle, chartLabel, cardText, csvFile, button }) => {
  const [graphData, setGraphData] = useState(null)
  let fileUrl
  if (csvFile !== null && csvFile !== undefined) {
    fileUrl = "http:" + csvFile.file.url
  } else {
    fileUrl = null
  }

  useEffect(() => {
    if (fileUrl !== null) {
      Papa.parse(`https://wbcg-cors-anywhere.herokuapp.com/${fileUrl}`, {
        download: true,
        complete: function (res) {
          let tempArr1 = []
          let tempArr2 = []
          res.data.map((dat, i) => {
            if (i > 0) {
              if (dat[1] !== "" && dat[1] !== undefined && dat[1] != null) {
                tempArr1.push(dat[1])
              }
              if (dat[2] !== "" && dat[2] !== undefined && dat[2] != null) {
                tempArr2.push(dat[2])
              }
            }
          })
          const dataObj = {
            labels: tempArr1,
            datasets: [{ backgroundColor: labelColors, data: tempArr2 }],
          }
          setGraphData(dataObj)
        },
      })
    }
  }, [csvFile])

  Chart.register(...registerables)
  const chartRef = useRef(null)
  let myChart1
  useEffect(() => {
    if (graphData !== null) {
      const config = {
        type: "pie",
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        data: graphData,
      }
      const chart = new Chart(chartRef.current.getContext("2d"), config)
      return () => chart.destroy()
    }
  })
  return (
    <>
      {!cardText && (
        <Flex
          bg="white"
          width={"100%"}
          boxShadow={"lg"}
          flexDirection={"column"}
          p={5}
          mb={5}
        >
          <Title variant={"h2"} fontSize={"18px"} mb={4}>
            {cardTitle}
          </Title>
          <Flex
            flexDirection={{ _: "column", lg: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box width={16} mb={{ _: 5, lg: 0 }}>
              {graphData && <canvas id="myChart" ref={chartRef} />}
            </Box>
            <Box width={{ _: 17, lg: 22 }} ml={{ _: 0, lg: 8 }}>
              {chartLabel && (
                <Box pb={4}>
                  <Title fontWeight="semibold" variant={"h3"} fontSize={"14px"}>
                    {chartLabel}
                  </Title>
                </Box>
              )}
              {graphData &&
                graphData.labels.map((label, i) => (
                  <Flex
                    mb={"9px"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Flex>
                      <Box
                        mt={2}
                        mr={3}
                        width={"12px"}
                        height={"12px"}
                        bg={labelColors[i]}
                      />
                      {label}
                    </Flex>
                    <Box>{graphData.datasets[0].data[i]}</Box>
                  </Flex>
                ))}
            </Box>
          </Flex>
        </Flex>
      )}
      {cardText && (
        <Flex
          bg="white"
          width={"100%"}
          boxShadow={"lg"}
          flexDirection={"column"}
          p={5}
          mb={5}
        >
          <Title
            fontWeight="regular"
            variant={"h2"}
            color={"primary"}
            fontSize={"22px"}
            mb={4}
          >
            {cardTitle}
          </Title>
          <Flex
            flexDirection={{ _: "column", lg: "row" }}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <Box flex={1} width="100%" mr={{ _: 0, lg: 6 }}>
              <RichText text={cardText} />
              {button && <Button {...button} mb={{ _: 6, lg: 0 }} />}
            </Box>
            <Flex
              flex={1}
              flexDirection={{ _: "column", md: "row" }}
              alignItems={"flex-start"}
              justifyContent={{ _: "center", lg: "flex-end" }}
              pr={{ _: 0, lg: 6 }}
              pl={{ _: 0, lg: 8 }}
              pt={{ _: 0, lg: 4 }}
            >
              <Box flex={1} width={9} mr={{ _: 8, lg: 0 }} mb={{ _: 4, lg: 0 }}>
                {graphData && <canvas id="myChart" ref={chartRef} />}
              </Box>
              <Box flex={2} ml={{ _: 0, lg: 8 }}>
                {chartLabel && (
                  <Box pb={4}>
                    <Title
                      fontWeight="semibold"
                      variant={"h3"}
                      fontSize={"14px"}
                    >
                      {chartLabel}
                    </Title>
                  </Box>
                )}
                {graphData &&
                  graphData.labels.map((label, i) => (
                    <Flex
                      mb={"9px"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Flex>
                        <Box
                          mt={2}
                          mr={3}
                          width={"12px"}
                          height={"12px"}
                          bg={labelColors[i]}
                        />
                        {label}
                      </Flex>
                    </Flex>
                  ))}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default React.memo(ChartCard)

export const query = graphql`
  fragment ChartCard on ContentfulChartCard {
    cardTitle
    chartLabel
    cardText {
      json
    }
    csvFile {
      file {
        fileName
        url
      }
    }
    button {
      ...Button
    }
  }
`
