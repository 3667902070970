import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { color } from "styled-system"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"

import { Title, Paragraph } from "components/atoms/Typography"
import SectionTitle from "components/molecules/SectionTitle"
import NewsletterSignup from "components/molecules/NewsletterSignup"

const StyledButton = styled.button`
  height: 7px;
  width: 7px;
  margin-right: 17px;
  border-radius: 100%;
  opacity: ${props => (props.active ? 1 : 0.2)};
  transition: opacity 0.2s ease;
  ${color}
`

const QuoteCarousel = ({
  sectionStyling,
  sectionTitle,
  quotes,
  includeNewsletterSignup,
  newsletterSignupTitle,
  newsletterSignupDescription,
  isFirstSection,
}) => {
  const [activeIdx, setActiveIdx] = React.useState(0)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIdx((activeIdx + 1) % quotes.length)
    }, 6000)
    return () => clearTimeout(timeout)
  }, [quotes, activeIdx])

  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        {sectionTitle && <SectionTitle {...sectionTitle} />}
        <Flex width={`${100 * quotes.length}%`}>
          {quotes.map((quote, idx) => {
            const active = idx === activeIdx
            return (
              <Flex
                minHeight="300px"
                flexDirection={{ _: "column-reverse", md: "row-reverse" }}
                justifyContent="space-between"
                alignItems="stretch"
                opacity={active ? 1 : 0}
                zIndex={active ? 2 : 1}
                position="relative"
                style={{
                  transition: "opacity 0.2s ease",
                  transform: `translateX(-${100 * idx}%)`,
                }}
                width="100%"
                height="100%"
                key={idx}
                bg="white"
                boxShadow="lg"
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  p={6}
                >
                  <Box>
                    <Title variant="h2" as="h3" mt={3}>
                      {quote.quote}
                    </Title>
                    <Paragraph mt={8} fontWeight="semibold">
                      {quote.name}
                    </Paragraph>
                    <Paragraph mb={6} mt={3}>
                      {quote.position}
                    </Paragraph>
                  </Box>
                  <Flex>
                    {quotes.map((_item, idx) => {
                      return (
                        <StyledButton
                          bg="primary"
                          active={idx === activeIdx}
                          onClick={() => setActiveIdx(idx)}
                        />
                      )
                    })}
                  </Flex>
                </Flex>
                <Box
                  style={{ overflow: "hidden" }}
                  position="relative"
                  flex="0 0 300px"
                >
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  >
                    <Img
                      style={{ height: "100%", width: "100%" }}
                      fluid={quote.image.fluid}
                    />
                  </Box>
                </Box>
              </Flex>
            )
          })}
        </Flex>
        {includeNewsletterSignup && (
          <NewsletterSignup
            my={8}
            title={newsletterSignupTitle}
            description={newsletterSignupDescription}
          />
        )}
      </StandardPadding>
    </Section>
  )
}

export default QuoteCarousel

export const query = graphql`
  fragment QuoteCarousel on ContentfulQuoteCarousel {
    sectionStyling {
      ...Section
    }
    quotes {
      quote
      name
      position: subtext
      image {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    includeNewsletterSignup
    newsletterSignupTitle
    newsletterSignupDescription
  }
`
