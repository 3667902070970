import React from "react"
import useBreakpoint from "hooks/use-breakpoint"
import Flex from "components/atoms/Flex"
import Button from "components/atoms/Button"
import Select from "components/atoms/Select"

const Filter = ({ selections, value, onChange, linkVariant, ...rest }) => {
  const isMobile = useBreakpoint("sm")

  if (isMobile) {
    return (
      <Select
        selections={selections}
        value={value}
        onChange={e => onChange(e.target.value)}
        label="Filter"
        mb={5}
      />
    )
  }

  return (
    <Flex {...rest}>
      {selections.map(selection => {
        return (
          <Button
            showArrow={false}
            key={selection.value}
            variant={linkVariant ? linkVariant : "link"}
            mr={5}
            onClick={() => onChange(selection.value)}
            color={value === selection.value ? "primary" : "text"}
          >
            {selection.label}
          </Button>
        )
      })}
    </Flex>
  )
}

export default Filter
