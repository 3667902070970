import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import "@reach/dialog/styles.css"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import FlexGrid from "components/atoms/FlexGrid"
// import Flex from "components/atoms/Flex"
// import Button from "components/atoms/Button"

import Filter from "components/molecules/Filter"
import TeamMemberCard from "components/molecules/TeamMemberCard"
import SectionTitle from "components/molecules/SectionTitle"

const NUM_TO_LOAD = 6

const TeamGrid = ({
  sectionStyling,
  sectionTitle,
  tags,
  teamMembers,
  isFirstSection,
}) => {
  const [listItems, setListItems] = useState(
    Array.from(Array(12).keys(), n => n + 1)
  )
  const [isFetching, setIsFetching] = useState(false)
  const [filter, setFilter] = useState("all")

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [filter])

  useEffect(() => {
    if (!isFetching) return
    fetchMoreListItems()
  }, [isFetching])

  function handleScroll() {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 1000
    ) {
      setIsFetching(true)
    }
    return
  }

  function fetchMoreListItems() {
    if (allLoaded) {
      setIsFetching(false)
      return
    }
    setTimeout(() => {
      setListItems(prevState => [
        ...prevState,
        ...Array.from(Array(NUM_TO_LOAD).keys(), n => n + prevState.length + 1),
      ])
      setIsFetching(false)
    }, 200)
  }

  let filteredItems = teamMembers.filter(member => {
    if (filter === "all") return teamMembers
    else if (filter !== "all") {
      const tagsArr = member.tags?.map(tag => tag.tag.toLowerCase())
      if (
        tagsArr !== null &&
        tagsArr !== undefined &&
        tagsArr.includes(filter)
      ) {
        return member
      }
    }
  })

  const allLoaded = listItems.length >= filteredItems.length

  let selectionValues = ["All"]
  let selections = [{ value: "all", label: "All" }]

  tags.map(tag => {
    if (!selectionValues.includes(tag)) {
      selectionValues.push(tag.tag)
      selections.push({
        value: tag.tag.toLowerCase(),
        label: tag.tag,
      })
    }
  })

  return (
    <>
      <Section
        {...sectionStyling}
        mt={isFirstSection && "-100px"}
        pt={isFirstSection && "100px"}
      >
        <StandardPadding
          pt={sectionStyling?.paddingTop}
          pb={sectionStyling?.paddingBottom}
        >
          <SectionTitle {...sectionTitle} mb={6} />
          <Filter
            selections={selections}
            onChange={val => {
              setFilter(val)
              setListItems(Array.from(Array(12).keys(), n => n + 1))
            }}
            value={filter}
            mb={6}
            // linkVariant={itemsBackgroundColor === "white" && "inverted"}
          />
          <FlexGrid columns={{ _: 1, md: 2, lg: 3 }} gutter={5}>
            {filteredItems.slice(0, listItems.length).map((item, idx) => (
              //   <ArticlePreview
              //     {...item}
              //     tags={[]}
              //     key={item.slug}
              //     variant={itemsBackgroundColor === "white" && "whiteCard"}
              //   />
              <Reveal key={idx}>
                <TeamMemberCard {...item} />
              </Reveal>
            ))}
          </FlexGrid>
          {/* {!allLoaded && (
            <Flex mt={8} flexDirection="column" alignItems="center">
              <Button
                showArrow={false}
                text="Load More"
                onClick={() => setNumLoaded(numLoaded + NUM_TO_LOAD)}
                //variant={itemsBackgroundColor === "white" ? "filled" : "link"}
              />
            </Flex>
          )} */}
        </StandardPadding>
      </Section>
    </>
  )
}

export default TeamGrid

export const query = graphql`
  fragment TeamGrid on ContentfulTeamGrid {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    tags {
      tag
    }
    teamMembers {
      name
      position
      tags {
        tag
      }
      image {
        description
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description {
        json
      }
      link {
        ...Link
      }
      email
      phone: phoneNumber
      linkedInLink
    }
  }
`
