import React from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import FlexGrid from "components/atoms/FlexGrid"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import RichText from "components/molecules/RichText"
import { Title } from "components/atoms/Typography"

import SectionTitle from "components/molecules/SectionTitle"

const RichTextCard = ({ text, button, title }) => {
  return (
    <Flex
      boxShadow={"lg"}
      flexDirection={"column"}
      px={6}
      py={7}
      borderTop={"6px solid blue"}
      maxWidth={"480px"}
      height={"100%"}
    >
      <Title color="primary" mb={5} as="h3" variant={"h2"}>
        {title}
      </Title>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <RichText style={{ whiteSpace: "pre-line" }} text={text} mb={5} />
        <Flex width={"100%"} justifyContent={"flex-end"}>
          <Button {...button} />
        </Flex>
      </Flex>
    </Flex>
  )
}

const RichTextCards = ({
  sectionStyling,
  sectionTitle,
  richTextCards,
  isFirstSection,
}) => {
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Reveal>
          <SectionTitle mb={6} {...sectionTitle} />
        </Reveal>
        <FlexGrid gutter={5} numColumns={2}>
          {richTextCards.map((card, idx) => (
            <Reveal key={idx}>
              <RichTextCard {...card} />
            </Reveal>
          ))}
        </FlexGrid>
      </StandardPadding>
    </Section>
  )
}

export default RichTextCards

export const query = graphql`
  fragment RichTextCards on ContentfulRichTextCards {
    sectionStyling {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    richTextCards {
      id
      text {
        json
      }
      title
      button {
        ...Button
      }
    }
  }
`
