import React, { useEffect, useRef, useState } from "react"
// import seamless from "seamless"

import Box from "components/atoms/Box"

const ContactForm = ({ src }) => {
  const iframeRef = useRef()
  const [height, setHeight] = useState(500)

  // https://jacobfilipp.com/iframe-height-autoresize-crossdomain/
  useEffect(() => {
    if (window) {
      window.addEventListener(
        "message",
        event => {
          if (event.data.height) {
            setHeight(Number(event.data.height))
          }
        },
        false
      )
    }
  }, [])

  return (
    <Box position="relative" width="100%">
      <Box width="100%" bg="white" boxShadow="md" p={{ _: 4, lg: 6 }}>
        <iframe
          title="Contact Form"
          ref={iframeRef}
          src={src}
          style={{
            height,
            minHeight: "500px",
            maxHeight: "2000px",
            width: "100%",
            border: 0,
          }}
        />
      </Box>
    </Box>
  )
}

export default ContactForm
