import React from "react"

import ImageText from "components/sections/ImageText"
import Cta from "components/sections/Cta"
import InfoCards from "components/sections/InfoCards"
import ImageCards from "components/sections/ImageCards"
import ArticleGrid from "components/sections/ArticleGrid"
import Text from "components/sections/Text"
import SubsectionSelector from "components/sections/SubsectionSelector"
import ImageBlock from "components/sections/ImageBlock"
import TitleArticles from "components/sections/TitleArticles"
import RecentArticles from "components/sections/RecentArticles"
import ImageQuote from "components/sections/ImageQuote"
import FeaturedMediaCarousel from "components/sections/FeaturedMediaCarousel"
// import TwitterMedia from "components/sections/TwitterMedia"
import InfoCardsAndRichText from "components/sections/InfoCardsAndRichText"
import ChartCards from "components/sections/ChartCards"
import Regions from "components/sections/Regions"
import Stats from "components/sections/Stats"
import RichTextCards from "components/sections/RichTextCards"
import TeamGrid from "components/sections/TeamGrid"
import QuoteCarousel from "components/sections/QuoteCarousel"
import Contact from "components/sections/Contact"
import ImageTextGroup from "components/sections/ImageTextGroup"
import Video from "components/sections/Video"

const Sections = ({ section }) => {
  switch (section.__typename) {
    case "ContentfulImageText":
      return <ImageText {...section} />
    case "ContentfulCta":
      return <Cta {...section} />
    case "ContentfulInfoCards":
      return <InfoCards {...section} />
    case "ContentfulRegions":
      return <Regions {...section} />
    case "ContentfulImageAndCards":
      return <ImageCards {...section} />
    case "ContentfulArticleGrid":
      return <ArticleGrid {...section} />
    case "ContentfulTextSection":
      return <Text {...section} />
    case "ContentfulSubsectionSelector":
      return <SubsectionSelector {...section} />
    case "ContentfulImageBlock":
      return <ImageBlock {...section} />
    case "ContentfulTitleAndPosts":
      return <TitleArticles {...section} />
    case "ContentfulQuoteCarousel":
      return <QuoteCarousel {...section} />
    case "ContentfulArticlePreview":
      return <RecentArticles {...section} />
    case "ContentfulImageAndQuote":
      return <ImageQuote {...section} />
    case "ContentfulFeaturedMediaCarousel":
      return <FeaturedMediaCarousel {...section} />
    // case "ContentfulTwitterMediaSection":
    //   return <TwitterMedia {...section} />
    case "ContentfulInfoCardsAndRichText":
      return <InfoCardsAndRichText {...section} />
    case "ContentfulChartCards":
      return <ChartCards {...section} />
    case "ContentfulStats":
      return <Stats {...section} />
    case "ContentfulRichTextCards":
      return <RichTextCards {...section} />
    case "ContentfulTeamGrid":
      return <TeamGrid {...section} />
    case "ContentfulContact":
      return <Contact {...section} />
    case "ContentfulImageTextGroup":
      return <ImageTextGroup {...section} />
    case "ContentfulVideoSection":
      return <Video {...section} />
    default:
      return null
  }
}

export default Sections
