import React from "react"
import MuiSelect from "@material-ui/core/Select"

import styled from "styled-components"
import { space, layout } from "styled-system"
import css from "@styled-system/css"
import { splitProps, shouldForwardProp } from "lib/styled-system/helpers"

const Wrapper = styled("div").withConfig({ shouldForwardProp })`
  ${space}
  & .MuiSelect-root {
    background-color: white;
    ${css({ boxShadow: "md", padding: 4, color: "text", fontFamily: "body" })}
  }
  & .MuiFilledInput-root {
    ${css({ borderRadius: "sm" })}
    ${layout}
  }
  & .MuiSelect-select:focus {
    background-color: white;
  }
  & option {
    ${css({ fontFamily: "body" })}
  }
`

const Select = props => {
  const { selections, children, ...otherProps } = props
  const { space, layout, rest } = splitProps(otherProps, ["space", "layout"])

  return (
    <Wrapper {...space} {...layout}>
      <MuiSelect native disableUnderline={true} variant="filled" {...rest}>
        {selections
          ? selections.map(selection => (
              <option value={selection.value} key={selection.value}>
                {selection.label}
              </option>
            ))
          : children}
      </MuiSelect>
    </Wrapper>
  )
}

Select.defaultProps = {
  minWidth: 12,
  height: 3,
}

export default Select
