import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import { Title, Paragraph } from "components/atoms/Typography"

const Video = ({
  sectionStyling,
  isFirstSection,
  video,
  title,
  titleVariant = "h2",
  description,
  textAlignment,
}) => {
  const StyledDiv = styled(Flex)`
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    iframe {
      width: 100% !important;
      height: ${props => {
        return `${props.videoHeight}px !important`
      }};
    }
  `
  const videoRef = useRef(null)
  const [videoWidth, setVideoWidth] = useState(-1)
  const [videoHeight, setVideoHeight] = useState(-1)
  const [iframeEmbed, setIframeEmbed] = useState(null)
  useLayoutEffect(() => {
    if (videoRef.current) {
      setVideoWidth(window.getComputedStyle(videoRef.current).width)
    }
    if (videoWidth !== -1) {
      setVideoHeight(parseInt(videoWidth.split("px")) * 0.56)
    }
  })
  useEffect(() => {
    if (video.foxBusinessVideoId) {
      setIframeEmbed(
        `<iframe aria-label="More+Videos" src="//video.foxbusiness.com/v/video-embed.html?video_id=${video.foxBusinessVideoId}" width="466" height="263" marginwidth="0" marginheight="0" frameborder="0" scrolling="no"></iframe>`
      )
    } else if (video.html) {
      setIframeEmbed(video.html.html)
    } else {
      setIframeEmbed(null)
    }
  }, [])

  if (!iframeEmbed) {
    return null
  }

  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding>
        <StyledDiv
          videoHeight={videoHeight}
          ref={videoRef}
          dangerouslySetInnerHTML={{ __html: iframeEmbed }}
        ></StyledDiv>
        <Title mt={6} mb={5} textAlign={textAlignment} variant={titleVariant}>
          {title}
        </Title>
        <Paragraph textAlign={textAlignment}>{description}</Paragraph>
      </StandardPadding>
    </Section>
  )
}

export default Video

export const query = graphql`
  fragment Video on ContentfulVideoSection {
    sectionStyling {
      ...Section
    }
    title
    titleVariant
    description
    textAlignment
    video {
      ... on ContentfulVideo {
        __typename
        title
        date
        source
        description
        image {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        tags {
          tag
        }
        html {
          html
        }
        foxBusinessVideoId
      }
    }
  }
`
