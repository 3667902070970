import React from "react"
import { graphql } from "gatsby"
import Page from "components/page/Page"
import "normalize.css"

const PageTemplate = ({ data }) => <Page data={data} />

export default PageTemplate

export const query = graphql`
  query PageBySlug($slug: String!) {
    contentfulSite {
      title
      header {
        ...Header
      }
      footer {
        ...Footer
      }
      metaDescription
      socialShareImage {
        file {
          url
        }
      }
      disclosureText {
        json
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      ...Page
    }
  }
`
