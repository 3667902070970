import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Card from "components/atoms/Card"
import Flex from "components/atoms/Flex"
import FlexGrid from "components/atoms/FlexGrid"
import Button from "components/atoms/Button"
import SectionTitle from "components/molecules/SectionTitle"
import StyledElem from "components/atoms/StyledElem"

import { Paragraph } from "components/atoms/Typography"

const BREAKPOINT = "md"

const StyledButton = StyledElem("button")

const HoverStyledButton = styled(StyledButton)`
  background: "background.0";
  transition: background 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
  &:hover {
    cursor: pointer;
    background: #4163f4;
  }
`

const Region = ({
  name,
  description,
  email,
  phoneNumber,
  active,
  theme,
  borderColorHexCode,
  ...rest
}) => {
  // console.log("theme:::", theme)
  return (
    <HoverStyledButton active={active} {...rest}>
      <Card
        width="100%"
        minWidth={"312px"}
        textAlign="left"
        boxShadow="md"
        px={4}
        py={3}
      >
        <Paragraph
          mb={2}
          color={active && "white"}
          fontWeight={700}
          fontSize="18px"
        >
          {name}
        </Paragraph>
        <Flex
          flexDirection={{ _: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Paragraph mb={1} color={active && "white"} fontSize="14px">
            {email}
          </Paragraph>
          <Paragraph color={active && "white"} fontSize="14px">
            {phoneNumber}
          </Paragraph>
        </Flex>
      </Card>
    </HoverStyledButton>
  )
}

const Regions = ({
  sectionStyling,
  sectionTitle,
  regions,
  pdf,
  isFirstSection,
}) => {
  const [active, setActive] = useState(0)

  return (
    <Section
      {...sectionStyling}
      bg={sectionStyling.theme === "dark" && 2}
      mt={isFirstSection && "-120px"}
      pt={isFirstSection && "120px"}
    >
      <StandardPadding>
        <SectionTitle mb={6} {...sectionTitle} />
        <Flex
          flexDirection={{ _: "column-reverse", [BREAKPOINT]: "row" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            // border="1px #4163F4 solid"
            display={{ _: "flex", [BREAKPOINT]: "block" }}
            flexWrap="wrap"
            flex={{ _: "initial", [BREAKPOINT]: "0 0 40%" }}
            justifyContent={{ _: "center", sm: "space-between" }}
          >
            {regions.map((region, idx) => {
              if (idx > 0) {
                return (
                  <Reveal key={idx}>
                    <Region
                      borderLeft={
                        idx === active
                          ? "5px #4267EB solid"
                          : `5px ${region.borderColorHexCode} solid`
                      }
                      display={{ _: "flex", [BREAKPOINT]: "block" }}
                      alignItems="stretch"
                      width={{
                        _: "100%",
                        sm: "calc(50% - 2px)",
                        [BREAKPOINT]: "100%",
                      }}
                      maxWidth={{ _: "400px", [BREAKPOINT]: "auto" }}
                      onMouseEnter={() => setActive(idx)}
                      onMouseLeave={() => setActive(0)}
                      active={idx === active}
                      {...region}
                      mb={2}
                      theme={sectionStyling.theme}
                    />
                  </Reveal>
                )
              }
            })}
          </Box>
          <Box
            position="relative"
            flex={{ _: "initial", [BREAKPOINT]: "0 0 55%" }}
            width={{ _: "100%", [BREAKPOINT]: "auto" }}
            mb={{ _: 6, [BREAKPOINT]: 0 }}
          >
            {regions.map(({ image }, idx) => {
              return (
                <Flex
                  style={{ transition: "opacity 0.4s ease" }}
                  position={idx !== 0 ? "absolute" : "static"}
                  width="100%"
                  top={0}
                  left={0}
                  key={idx}
                  opacity={idx === active ? "1" : "0"}
                  alignItems="center"
                >
                  <Box width="100%" position="relative">
                    <Img width="100%" fluid={image.fluid} />
                  </Box>
                </Flex>
              )
            })}
          </Box>
        </Flex>
        {pdf?.file?.url && (
          <Flex width="100%" justifyContent={{ _: "center", sm: "flex-start" }}>
            <Button
              link={{ href: pdf.file.url }}
              mt={7}
              variant="secondarySquare"
              bg={sectionStyling.theme === "dark" && "#4163F4"}
            >
              Download PDF
            </Button>
          </Flex>
        )}
      </StandardPadding>
    </Section>
  )
}

export default Regions

export const query = graphql`
  fragment Regions on ContentfulRegions {
    sectionTitle {
      ...SectionTitle
    }
    sectionStyling {
      ...Section
    }
    regions {
      name
      description {
        description
      }
      email
      phoneNumber
      image {
        description
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      borderColorHexCode
    }
    pdf {
      file {
        url
      }
    }
  }
`
