import React, { useState, useEffect, forwardRef } from "react"
import { format } from "date-fns"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"
import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"

import Link from "components/utils/Link"

import ProportionalBox from "components/atoms/ProportionalBox"
import { Title, Label, Paragraph, Text } from "components/atoms/Typography"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import Card from "components/atoms/Card"
import RichText from "./RichText"

import Email from "components/icons/email.svg"
import Phone from "components/icons/phone.svg"
import Linkedin from "components/icons/linkedin.svg"
import CloseIcon from "components/icons/close-icon-x.svg"

const StyledTitle = styled(Title)`
  max-width: 300px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledCard = styled(Card)`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const modalBackground = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const modalPopup = keyframes`
  0% {
    transform: translateY(10vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
`

const StyledDialogOverlay = styled(DialogOverlay)`
  background-color: rgba(28, 39, 77, 0.9);
  z-index: 1000;
  will-change: opacity;
  animation: ${modalBackground} 0.3s cubic-bezier(0, 0.77, 0.28, 0.99);
`

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  width: 90vw;
  max-width: 500px;
  @media (min-width: 768px) {
    width: 90vw;
    max-width: 960px;
  }
  will-change: top, opacity;
  animation: ${modalPopup} 0.3s cubic-bezier(0, 0.77, 0.28, 0.99);
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 400px;
  text-align: left;
  height: 100%;
  width: 100%;
`

const Image = styled(Img)`
  height: 100%;
`

const TeamMemberCard = ({
  image,
  name,
  description,
  position,
  email,
  phone,
  linkedInLink,
  link,
  ...rest
}) => {
  const [showDialog, setShowDialog] = React.useState(false)

  const hasBottomLinks = email || phone || linkedInLink

  return (
    <>
      <Button onClick={() => setShowDialog(true)}>
        <ProportionalBox mb={3} ratio={1.33} position={"relative"}>
          <Image {...image} style={{ height: "100%" }} />
        </ProportionalBox>
        <Box>
          <Title color={"text"} variant="h3" mb={3}>
            {name}
          </Title>
          <Label color={"primary"} mb={3}>
            {position}
          </Label>
        </Box>
      </Button>
      <StyledDialogOverlay
        isOpen={showDialog}
        onDismiss={() => setShowDialog(false)}
      >
        <StyledDialogContent>
          <Box bg="white" boxShadow="lg">
            <Flex justifyContent={"flex-end"} pt={5} pr={5} bg={"white"}>
              <CloseIcon
                onClick={() => setShowDialog(false)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
            <Flex
              flexDirection={{ _: "column-reverse", md: "row" }}
              p={{ _: 6, md: 8 }}
            >
              <Box mt={{ _: 5, md: 0 }} flex="0 0 50%">
                <Title>{name}</Title>
                <Paragraph fontSize="18px" color="primary">
                  {position}
                </Paragraph>
                <RichText mt="20px" text={description} />
                {link && (
                  <Link to={`/${link.to.slug}`}>
                    <Box color="primary">{link.hrefText}</Box>
                  </Link>
                )}
                {/* {link && link.hrefText} */}
                {hasBottomLinks && (
                  <Flex flexDirection={{ _: "column", xs: "row" }} mt={4}>
                    {email && (
                      <a style={{ marginTop: "12px" }} href={`mailto:${email}`}>
                        <Box display="inline-block" mr={3}>
                          <Email />
                        </Box>
                        <Text style={{ marginRight: "23px" }} color="primary">
                          Email
                        </Text>
                      </a>
                    )}
                    {phone && (
                      <a style={{ marginTop: "12px" }} href={`tel:${phone}`}>
                        <Box display="inline-block" mr={3}>
                          <Phone />
                        </Box>
                        <Text style={{ marginRight: "23px" }} color="primary">
                          Phone
                        </Text>
                      </a>
                    )}
                    {linkedInLink && (
                      <a
                        style={{ marginTop: "12px" }}
                        rel="noreferrer noopener"
                        target="_blank"
                        href={linkedInLink}
                      >
                        <Box display="inline-block" mr={3}>
                          <Linkedin />
                        </Box>
                        <Text style={{ marginRight: "23px" }} color="primary">
                          LinkedIn
                        </Text>
                      </a>
                    )}
                  </Flex>
                )}
              </Box>
              <Box
                style={{ overflow: "hidden" }}
                flex="0 0 50%"
                minHeight={{ _: "200px", sm: "250px" }}
                position="relative"
                pl={{ _: 0, md: 7 }}
              >
                <ProportionalBox
                  ratio={1.33}
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                >
                  <Image
                    imgStyle={{ width: "100%", height: "100%" }}
                    {...image}
                  />
                </ProportionalBox>
              </Box>
            </Flex>
          </Box>
        </StyledDialogContent>
      </StyledDialogOverlay>
    </>
  )
}

export default TeamMemberCard
