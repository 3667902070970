import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import { Title, Label, Paragraph } from "components/atoms/Typography"
import Box from "components/atoms/Box"

const SectionTitle = ({
  title,
  variant,
  as,
  label,
  labelVariant,
  labelSizeVariant,
  description,
  descriptionSize,
  align,
  ...rest
}) => {
  const largeDescription = descriptionSize === "large"
  return (
    <Box mx={align === "center" ? "auto" : ""} {...rest}>
      {label && labelVariant !== "below-title" && (
        <Label mb={4} textAlign={align} variant={labelSizeVariant}>
          {label.toUpperCase()}
        </Label>
      )}
      <Title
        variant={variant || undefined}
        as={as || variant || undefined}
        textAlign={align}
      >
        {title}
      </Title>
      {label && labelVariant === "below-title" && (
        <Paragraph mt={1} mb={4} textAlign={align}>
          {label}
        </Paragraph>
      )}
      {description && (
        <Paragraph
          mt={largeDescription ? 4 : 3}
          textAlign={align}
          variant={largeDescription ? "h2" : "p"}
        >
          {description.description}
        </Paragraph>
      )}
    </Box>
  )
}

SectionTitle.strapiProps = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["h1", "h2"]).isRequired,
  label: PropTypes.string,
  description: PropTypes.object,
  descriptionSize: PropTypes.oneOf(["normal", "large"]),
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

SectionTitle.propTypes = {
  ...SectionTitle.strapiProps,
  ...Box.propTypes,
}

export default SectionTitle

export const query = graphql`
  fragment SectionTitle on ContentfulSectionTitle {
    title
    variant
    label
    labelVariant
    align
    description {
      description
    }
    descriptionSize
  }
`
