import React from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import FlexGrid from "components/atoms/FlexGrid"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"

import InfoCard from "components/molecules/InfoCard"
import SectionTitle from "components/molecules/SectionTitle"
import RichText from "components/molecules/RichText"

const InfoCardsRichText = ({
  sectionStyling,
  sectionTitle,
  infoCards,
  richText,
  cardsBackgroundColor,
  innerCardAlignment,
  cardsStyle,
  isFirstSection,
}) => {
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding>
        <Reveal>
          <SectionTitle {...sectionTitle} mb={5} />
        </Reveal>
        <Flex flexDirection={{ _: "column", lg: "row" }}>
          <Reveal>
            <FlexGrid
              numColumns={1}
              gutter={5}
              maxWidth={32}
              mr={{ _: 0, lg: 5 }}
              mb={{ _: 6, lg: 0 }}
            >
              {infoCards.map((card, i) => (
                <InfoCard
                  {...card}
                  cardStyle={cardsStyle}
                  align={innerCardAlignment}
                  bg={cardsBackgroundColor === "white" ? "white" : undefined}
                  boxShadow={cardsBackgroundColor === "white" ? "lg" : "none"}
                  key={i}
                />
              ))}
            </FlexGrid>
          </Reveal>
          <Reveal>
            <Box maxWidth={24}>
              <RichText text={richText} />
            </Box>
          </Reveal>
        </Flex>
      </StandardPadding>
    </Section>
  )
}

export default InfoCardsRichText

export const query = graphql`
  fragment InfoCardsAndRichText on ContentfulInfoCardsAndRichText {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    infoCards {
      ...InfoCard
    }
    richText {
      json
    }
    cardsBackgroundColor
    innerCardAlignment
    cardsStyle
  }
`
