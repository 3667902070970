import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"
import Image from "components/utils/Image"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import FlexGrid from "components/atoms/FlexGrid"
import Button from "components/atoms/Button"

import RichText from "components/molecules/RichText"
import SectionTitle from "components/molecules/SectionTitle"

const ImageText = ({
  sectionStyling,
  sectionTitle,
  button,
  image,
  imageLocation,
  imageWidth,
  isFirstSection,
  richText,
  disclosureText,
  ...rest
}) => {
  let fallBackDisclosureText
  if (!disclosureText) {
    fallBackDisclosureText = rest?.section?.disclosureText
  }
  //override imageLocation to ensure the overflow style is applied correctly
  if (imageWidth === "overflow") {
    imageLocation = "right"
  }
  if (imageWidth === "left-flush") {
    imageLocation = "left"
  }

  const left = imageLocation === "left"
  const right = imageLocation === "right"
  const overflow = imageWidth === "overflow"

  let imageMarginRight = 0
  if (imageWidth === "overflow") {
    imageMarginRight = { _: 0, lg: "-140px" }
  }
  if (left) {
    imageMarginRight = { _: 0, lg: 7 }
  }

  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
      overflow={imageWidth === "default-square" && "visible"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop ? sectionStyling.paddingTop : 9}
        pb={
          imageWidth === "default-square"
            ? "0px"
            : sectionStyling?.paddingBottom
            ? sectionStyling.paddingBottom
            : 11
        }
        mb={
          imageWidth === "default-square" && { _: 0, md: "-50px", lg: "-50px" }
        }
      >
        <Flex
          justifyContent={
            left && imageWidth === "default-square" ? "flex-start" : null
          }
          flexDirection={
            (left &&
              imageWidth !== "default-square" && {
                _: "column-reverse",
                lg: "row-reverse",
              }) ||
            (right && !overflow && { _: "column-reverse", lg: "row" }) ||
            (overflow && right && { _: "column", lg: "row" }) ||
            (imageWidth === "left-flush" && {
              _: "column",
              lg: "row-reverse",
            }) ||
            (left &&
              imageWidth === "default-square" && {
                _: "column-reverse",
                md: "row-reverse",
              })
          }
          width={"100%"}
          position={"relative"}
        >
          <Reveal height="100%">
            <Flex
              maxWidth={
                left && imageWidth === "default-square"
                  ? null
                  : overflow
                  ? { _: 30, lg: 19 }
                  : 30
              }
              mb={{ _: 4, lg: 0 }}
              flexDirection="column"
              alignItems={sectionTitle.align === "center" && "center"}
              justifyContent={"center"}
              height="100%"
              mx="auto"
              mr={left && imageWidth === "default-square" ? 0 : 7}
            >
              {!richText && (
                <SectionTitle
                  {...sectionTitle}
                  mt={imageWidth === "left-flush" ? { _: 0, lg: 10 } : 0}
                  maxWidth={
                    imageWidth === "default-square" && { _: "100%", md: 29 }
                  }
                  ml={
                    left && imageWidth === "default-square" && { _: 0, md: 5 }
                  }
                />
              )}
              {richText && (
                <>
                  <SectionTitle
                    {...sectionTitle}
                    description={null}
                    mb={3}
                    mt={imageWidth === "left-flush" ? { _: 0, lg: 10 } : 0}
                    maxWidth={
                      imageWidth === "default-square" && { _: "100%", md: 29 }
                    }
                    ml={
                      left && imageWidth === "default-square" && { _: 0, md: 5 }
                    }
                  />
                  <RichText text={richText} />
                </>
              )}
              {button && (
                <Button
                  mt={sectionTitle.description ? 4 : 6}
                  {...button}
                  disclosureText={disclosureText || fallBackDisclosureText}
                />
              )}
            </Flex>
          </Reveal>
          {image && (
            <Reveal flexGrow={1}>
              <Box
                mx={imageWidth === "left-flush" ? "0" : "auto"}
                ml={imageWidth === "left-flush" ? "-240px" : ""}
                mr={imageMarginRight}
                boxShadow={imageWidth === "left-flush" ? "none" : "sm"}
                mb={{ _: 4, lg: 0 }}
                maxWidth={
                  imageWidth === "default-square" && { _: "260px", lg: "398px" }
                }
                minWidth={imageWidth === "default-square" && "300px"}
              >
                <Image {...image} />
              </Box>
            </Reveal>
          )}
        </Flex>
      </StandardPadding>
    </Section>
  )
}

export default ImageText

ImageText.propTypes = {
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps),
  image: PropTypes.object,
  imageLocation: PropTypes.oneOf(["right", "left", "center"]),
  imageWidth: PropTypes.oneOf(["default", "full"]),
}

export const query = graphql`
  fragment ImageText on ContentfulImageText {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    text {
      text
    }
    richText {
      json
    }
    button {
      ...Button
    }
    image {
      description
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageLocation
    imageWidth
  }
`
