import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Button from "components/atoms/Button"

import Link from "components/utils/Link"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import FlexGrid from "components/atoms/FlexGrid"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { Text } from "components/atoms/Typography"

import RichText from "components/molecules/RichText"
import SectionTitle from "components/molecules/SectionTitle"
import ContactForm from "components/molecules/ContactForm"

const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  border: 1px solid #d9def1;
  padding: 16px;
  margin-bottom: 10px;
  margin-top: 8px;
  font-family: "'Nunito Sans', sans-serif";
`

const Label = ({ children, ...rest }) => {
  return (
    <label style={{ marginBottom: "8px" }} {...rest}>
      <Text fontSize="12px">{children}</Text>
    </label>
  )
}

const InvestmentProcessOverviewForm = () => {
  return (
    <form
      action="https://jump.rbadvisors.com/l/746533/2021-06-14/j18kv"
      method="post"
    >
      <Label for="Email">Email</Label>
      <StyledInput required name="Email" type="email" />
      <Label for="First Name">First Name</Label>
      <StyledInput required name="First Name" type="text" />
      <Label for="Last Name">Last Name</Label>
      <StyledInput required name="Last Name" type="text" />
      <Label for="State">State</Label>
      <StyledInput required name="State" type="text" />
      <Label for="Zip Code">Zip Code</Label>
      <StyledInput required name="Zip Code" type="number" />
      <Label for="Investor Type">Investor Type</Label>
      <StyledInput required name="Investor Type" type="text" />
      <Button
        mt={5}
        buttonColor="primary"
        width={{ _: "110px", sm: "136px" }}
        position="relative"
        zIndex={2}
        height="40px"
        variant="filled"
        onClick={() => console.log("subscribe")}
        type="input"
      >
        Submit
      </Button>
    </form>
  )
}

const InstitutionalForm = () => {
  return (
    <form
      action="https://jump.rbadvisors.com/l/746533/2021-06-14/j18lb"
      method="post"
    >
      <Label for="Email">Email</Label>
      <StyledInput required name="Email" type="email" />
      <Label for="First Name">First Name</Label>
      <StyledInput required name="First Name" type="text" />
      <Label for="Last Name">Last Name</Label>
      <StyledInput required name="Last Name" type="text" />
      <Label for="Company">Company</Label>
      <StyledInput required name="Company" type="text" />
      <Button
        mt={5}
        buttonColor="primary"
        width={{ _: "110px", sm: "136px" }}
        position="relative"
        zIndex={2}
        height="40px"
        variant="filled"
        onClick={() => console.log("subscribe")}
        type="input"
      >
        Submit
      </Button>
    </form>
  )
}

const Contact = ({
  isFirstSection,
  sectionStyling,
  sectionTitle,
  contactForm,
  pardotEmbedUrl,
  richText,
  links,
}) => {
  // console.log("links:::", links)
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        py={8}
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <FlexGrid columns={{ _: 1, md: 2 }} gutter={7}>
          <Flex flexDirection={"column"}>
            {sectionTitle && <SectionTitle {...sectionTitle} />}
            {richText && <RichText text={richText} />}
            {links &&
              links.map((link, idx) => {
                //console.log("link:::", link)
                return (
                  <Box pb={3}>
                    <Link {...link}>
                      <Text color={"primary"}>{link.hrefText}</Text>
                    </Link>
                  </Box>
                )
              })}
          </Flex>
          <Box
            p={contactForm === "pardot form" && pardotEmbedUrl ? "0px" : "33px"}
            bg="white"
            boxShadow="md"
          >
            {contactForm === "investment process overview" && (
              <InvestmentProcessOverviewForm />
            )}
            {contactForm === "institutional" && <InstitutionalForm />}
            {contactForm === "pardot form" && pardotEmbedUrl && (
              <ContactForm src={pardotEmbedUrl} />
            )}
          </Box>
        </FlexGrid>
      </StandardPadding>
    </Section>
  )
}

export default Contact

export const query = graphql`
  fragment Contact on ContentfulContact {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    contactForm
    pardotEmbedUrl
    richText {
      json
    }
    links {
      ...Link
    }
  }
`
