import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { color } from "styled-system"

import Link from "components/utils/Link"
import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import Button from "components/atoms/Button"

import VideoDialog from "components/molecules/VideoDialog"
import NewsletterSignup from "components/molecules/NewsletterSignup"

import { Title, Label, Text, Paragraph } from "components/atoms/Typography"

import LeftArrow from "components/icons/white-arrow-left.svg"
import RightArrow from "components/icons/white-arrow-right.svg"
import PlayIcon from "components/icons/blue-play-video-icon.svg"

const StyledButton = styled.button`
  height: 7px;
  width: 7px;
  margin-right: 17px;
  border-radius: 100%;
  opacity: ${props => (props.active ? 1 : 0.2)};
  transition: opacity 0.2s ease;
  ${color}
`

const CardCarousel = ({
  sectionStyling,
  items,
  sectionTitle,
  isFirstSection,
  includeNewsletterSignup,
  newsletterSignupTitle,
  newsletterSignupDescription,
  ...rest
}) => {
  const [showDialog, setShowDialog] = useState(null)
  const [activeIdx, setActiveIdx] = React.useState(0)

  const disclosureText = rest?.section?.disclosureText

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIdx((activeIdx + 1) % items.length)
    }, 6000)
    return () => clearTimeout(timeout)
  }, [items, activeIdx])

  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Box position={"relative"}>
          <Flex width={`${100 * items.length}%`}>
            {items.map((item, idx) => {
              const active = idx === activeIdx
              return (
                <Flex
                  minHeight="300px"
                  justifyContent="space-between"
                  alignItems="stretch"
                  opacity={active ? 1 : 0}
                  zIndex={active ? 2 : 1}
                  position="relative"
                  style={{
                    transition: "opacity 0.2s ease",
                    transform: `translateX(-${100 * idx}%)`,
                  }}
                  width="100%"
                  height="100%"
                  key={idx}
                  bg="white"
                  boxShadow="lg"
                >
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    p={6}
                  >
                    <Box>
                      <Label color="gray">
                        {sectionTitle?.title || "Featured News Items"}
                      </Label>
                      <Title mt={2} color={"primary"}>
                        {item.title}
                      </Title>
                      <Paragraph py={2} fontSize={"11px"} color={"black"}>
                        {item.description}
                      </Paragraph>
                      {item.__typename === "ContentfulVideo" && (
                        <>
                          <Button
                            mt={4}
                            paddingX={0}
                            onClick={() => setShowDialog(idx)}
                            variant="link"
                          >
                            Watch the video
                          </Button>
                          <VideoDialog
                            show={showDialog === idx}
                            html={item.html && item.html}
                            foxBusinessVideoId={
                              item.foxBusinessVideoId && item.foxBusinessVideoId
                            }
                            close={() => setShowDialog(null)}
                          />
                        </>
                      )}
                      {item.location && (
                        <Box mt={4}>
                          <Button variant={"link"}>
                            <Link to={item}>
                              <Text color="primary">Read More</Text>
                            </Link>
                          </Button>
                        </Box>
                      )}
                      {item.href && (
                        <Box mt={4}>
                          <Button variant={"link"}>
                            <Link
                              disclosureText={disclosureText}
                              href={item.href}
                            >
                              <Text color="primary">Read More</Text>
                            </Link>
                          </Button>
                        </Box>
                      )}
                    </Box>
                    <Flex>
                      {items.map((_item, idx) => {
                        return (
                          <StyledButton
                            bg="primary"
                            active={idx === activeIdx}
                            onClick={() => setActiveIdx(idx)}
                          />
                        )
                      })}
                    </Flex>
                  </Flex>
                  {(item.__typename === "ContentfulArticleExternalLink" ||
                    item.__typename === "ContentfulArticle") && (
                    <Box flex="0 0 50%" maxHeight={300} pb={0}>
                      {item.href && (
                        <Link disclosureText={disclosureText} href={item.href}>
                          <Img
                            style={{ maxHeight: "300px" }}
                            imgStyle={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "100%",
                            }}
                            fluid={item.image.fluid}
                          />
                        </Link>
                      )}
                      {!item.href && (
                        <Link showDisclosure={false} href={item.slug}>
                          <Img
                            style={{ maxHeight: "300px" }}
                            imgStyle={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "100%",
                            }}
                            fluid={item.image.fluid}
                          />
                        </Link>
                      )}
                    </Box>
                  )}
                  {item.__typename === "ContentfulVideo" && (
                    <Box flex="0 0 50%" position={"relative"}>
                      <Flex
                        position={"absolute"}
                        width={"100%"}
                        height={"100%"}
                        zIndex={300}
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowDialog(idx)}
                      >
                        <Box width={"44px"} height={"44px"}>
                          <PlayIcon className={"play-icon"} />
                        </Box>
                      </Flex>
                      <Img
                        style={{ height: "100%" }}
                        imgStyle={{ width: "100%", height: "100%" }}
                        fluid={item.image.fluid}
                      />
                    </Box>
                  )}
                </Flex>
              )
            })}
          </Flex>
          <Box
            mt={4}
            position={{ _: "relative", sm: "absolute" }}
            width={{ _: "100px", sm: "108%" }}
            top={{ _: undefined, sm: "45%" }}
            left={{ _: undefined, sm: "-4%" }}
          >
            <Flex width={"100%"} justifyContent={"space-between"}>
              <button
                onClick={() => {
                  if (activeIdx === 0) {
                    setActiveIdx(items.length - 1)
                  } else {
                    setActiveIdx(activeIdx - 1)
                  }
                }}
              >
                <LeftArrow />
              </button>
              <button
                onClick={() => setActiveIdx((activeIdx + 1) % items.length)}
              >
                <RightArrow />
              </button>
            </Flex>
          </Box>
        </Box>
        {includeNewsletterSignup && (
          <NewsletterSignup
            mt={8}
            mb={5}
            title={newsletterSignupTitle}
            description={newsletterSignupDescription}
          />
        )}
      </StandardPadding>
    </Section>
  )
}

export default CardCarousel
