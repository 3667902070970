import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import css from "@styled-system/css"
import { animation } from "lib/styled-system/system"

const Button = styled.button`
  width: ${props => (props.variant === "bar" ? "32px" : "8px")};
  height: ${props => (props.variant === "bar" ? "3px" : "8px")};
  border-radius: ${props => (props.variant === "bar" ? "0px" : "8px")};
  margin: 0 8px;
  transition-property: opacity;
  opacity: ${props => (props.active ? "1" : "0.25")};
  &:hover {
    opacity: ${props => (props.active ? "1" : "0.5")};
  }
  ${css({ bg: "text" })}
  ${animation}
`

const Indicator = ({ active, onClick, idx, variant }) => {
  return (
    <Button
      aria-label={`Go to slide ${idx}`}
      onClick={onClick ? onClick : undefined}
      active={active}
      transitionDuration="fast"
      variant={variant}
    />
  )
}

Indicator.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  idx: PropTypes.number,
}

export default Indicator
