import React from "react"
import { graphql } from "gatsby"

import ThemeSwitcher from "components/utils/ThemeSwitcher"
import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import FlexGrid from "components/atoms/FlexGrid"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"

import InfoCard from "components/molecules/InfoCard"
import SectionTitle from "components/molecules/SectionTitle"

const InfoCards = props => {
  const {
    sectionStyling,
    sectionTitle,
    infoCards,
    button,
    cardsTheme,
    numColumns,
    cardsBackgroundColor,
    innerCardAlignment,
    iconSize,
    iconPosition,
    alignCards,
    cardsStyle,
    isFirstSection,
    ...rest
  } = props

  let columns
  if (numColumns) {
    switch (numColumns) {
      case 1:
        columns = { _: 1 }
        break
      case 2:
        columns = { _: 1, sm: 2 }
        break
      case 3:
        columns = { _: 1, sm: 2, md: 3 }
        break
      case 4:
        columns = { _: 1, sm: 2, md: 4 }
        break
      case 5:
        columns = { _: 1, xs: 2, sm: 3, lg: 5 }
        break
      default:
        columns = { _: 1, xs: 2, md: 3, lg: 3, xl: 5 }
    }
  }
  const tall = iconPosition === "top" && infoCards && infoCards[0].button
  const tight = sectionTitle?.align === "left" && button?.variant === "link"

  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Flex
          flexDirection={
            alignCards === "right" ? { _: "column", lg: "row" } : "column"
          }
        >
          <Reveal>
            <SectionTitle
              {...sectionTitle}
              maxWidth={alignCards === "right" ? 27 : 42}
            />
          </Reveal>
          {button && (
            <Reveal>
              <Flex
                mt={tight ? 4 : 5}
                flexDirection="column"
                alignItems={sectionTitle.align === "center" ? "center" : "left"}
              >
                <Button {...button} />
              </Flex>
            </Reveal>
          )}

          <ThemeSwitcher theme={cardsBackgroundColor === "white" && "light"}>
            <Reveal>
              <Box
                mt={7}
                ml={
                  numColumns === 1 && alignCards === "right"
                    ? { _: 0, md: 8 }
                    : 0
                }
              >
                <FlexGrid columns={columns} gutter={5} align="left">
                  {infoCards.map((infoCard, i) => (
                    <InfoCard
                      {...rest}
                      {...infoCard}
                      cardStyle={cardsStyle}
                      align={innerCardAlignment}
                      theme={cardsTheme}
                      bg={
                        cardsBackgroundColor === "white" ? "white" : undefined
                      }
                      //boxShadow={cardsShadow}
                      boxShadow={
                        cardsBackgroundColor === "white" ? "lg" : "none"
                      }
                      iconSize={iconSize}
                      iconPosition={iconPosition}
                      tall={tall}
                      key={i}
                      cardStyle={cardsStyle}
                    />
                  ))}
                </FlexGrid>
              </Box>
            </Reveal>
          </ThemeSwitcher>
        </Flex>
      </StandardPadding>
    </Section>
  )
}

export default InfoCards

export const query = graphql`
  fragment InfoCards on ContentfulInfoCards {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    infoCards {
      ...InfoCard
    }
    button {
      ...Button
    }
    cardsStyle
    numColumns
    cardsBackgroundColor
    innerCardAlignment
    iconSize
    iconPosition
    alignCards
  }
`
