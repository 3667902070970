import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Button from "components/atoms/Button"

import SectionTitle from "components/molecules/SectionTitle"

const Cta = ({
  sectionStyling,
  sectionTitle,
  button,
  secondaryButton,
  layout,
  isFirstSection,
}) => {
  const horizontal = layout === "horizontal"
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        py={9}
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Reveal>
          <Flex
            flexDirection={horizontal ? { _: "column", md: "row" } : "column"}
            alignItems="center"
          >
            <SectionTitle
              {...sectionTitle}
              mb={horizontal ? { _: 4, md: 0 } : 6}
              maxWidth={42}
              width={horizontal && { _: "100%", md: "55%" }}
              mr={horizontal ? { _: 0, md: 7 } : "auto"}
            />
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              width={horizontal && { _: "100%", md: "45%" }}
              ml={horizontal && { _: 0, md: 7 }}
              mt={horizontal && 4}
            >
              <Button {...button} />
              {secondaryButton && <Button {...secondaryButton} ml={4} />}
            </Flex>
          </Flex>
        </Reveal>
      </StandardPadding>
    </Section>
  )
}

Cta.defaultProps = {
  layout: "vertical",
}

Cta.propTypes = {
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps).isRequired,
  secondaryButton: PropTypes.shape(Button.strapiProps).isRequired,
  layout: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,
}

export default Cta

export const query = graphql`
  fragment Cta on ContentfulCta {
    sectionStyling: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    secondaryButton {
      ...Button
    }
    layout
  }
`
