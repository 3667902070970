import React, { useContext } from "react"
import { graphql } from "gatsby"
import { ThemeContext } from "styled-components"

import ThemeSwitcher from "components/utils/ThemeSwitcher"
import Reveal from "components/utils/Reveal"
import Image from "components/utils/Image"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import FlexGrid from "components/atoms/FlexGrid"
import Button from "components/atoms/Button"

import InfoCard from "components/molecules/InfoCard"
import SectionTitle from "components/molecules/SectionTitle"
import { Paragraph } from "components/atoms/Typography"

const BREAKPOINT_SUB_IMAGE_TEXT = "xl"

const SideImage = ({ align, image, imageSubText, ...rest }) => {
  return (
    <Box
      // position={{ _: "absolute", [BREAKPOINT_SUB_IMAGE_TEXT]: "static" }}
      zIndex={1}
      width="100%"
      maxWidth={"562px"}
      top={0}
      right={align === "right" ? 0 : null}
      left={align === "left" ? 0 : null}
      mr={align === "left" ? "71px" : 0}
      ml={align === "left" ? "-242px" : 5}
      mr={align === "right" ? "-242px" : 5}
      {...rest}
    >
      {image && (
        <Reveal>
          <Box mt={{ _: 8, [BREAKPOINT_SUB_IMAGE_TEXT]: 0 }}>
            <Image {...image} />
          </Box>
        </Reveal>
      )}
      {imageSubText && (
        <Box
          flexDirection="column"
          mt={7}
          display={{ _: "none", [BREAKPOINT_SUB_IMAGE_TEXT]: "flex" }}
          alignItems={align === "left" ? "flex-end" : "flex-start"}
        >
          {imageSubText.map(text => {
            return (
              <Paragraph maxWidth={"415px"} pl={5} mb={4}>
                {text}
              </Paragraph>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const ImageCards = ({
  sectionStyling,
  sectionTitle,
  infoCards,
  cardsBackgroundColor,
  image,
  imageSubText,
  cardsStyle,
  cardsColumns = 1,
  align,
  isFirstSection,
  button,
  ...rest
}) => {
  let { space } = useContext(ThemeContext)
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        <Flex width="100%" alignItems="center" flexDirection={"row"}>
          <Box width="100%">
            <Reveal>
              <SectionTitle {...sectionTitle} mb={8} maxWidth={50} />
            </Reveal>
            <Flex
              width="100%"
              position={"relative"}
              alignItems="flex-start"
              flexDirection={
                align === "left" ? { _: "row-reverse" } : { _: "row" }
              }
              justifyContent="space-between"
              mb={0}
              pb={0}
            >
              <ThemeSwitcher
                theme={cardsBackgroundColor === "white" && "light"}
              >
                <FlexGrid
                  flex={{
                    _: `0 0 calc(100% + ${space[5]}px)`,
                    [BREAKPOINT_SUB_IMAGE_TEXT]: "initial",
                  }}
                  maxWidth={44}
                  gutter={5}
                  columns={cardsColumns === 2 ? { _: 1, sm: 2 } : { _: 1 }}
                  zIndex={200}
                >
                  {infoCards.map((infoCard, i) => (
                    <Reveal key={i}>
                      <Flex
                        justifyContent={
                          align === "left" ? "flex-end" : "flex-start"
                        }
                        width="100%"
                      >
                        <Box maxWidth="584px" width="100%">
                          <InfoCard
                            {...rest}
                            width="100%"
                            maxWidth="none"
                            {...infoCard}
                            cardStyle={cardsStyle}
                            bg={
                              cardsBackgroundColor === "white"
                                ? "white"
                                : {
                                    _: "white",
                                    lg: "transparent",
                                  }
                            }
                            boxShadow={
                              cardsBackgroundColor === "white" ? "lg" : "none"
                            }
                            iconSize={"small"}
                            iconPosition={"right"}
                            borderRadius={cardsBackgroundColor === "none" && 10}
                          />
                        </Box>
                      </Flex>
                    </Reveal>
                  ))}
                </FlexGrid>
                <SideImage
                  position="relative"
                  left={
                    align === "right"
                      ? {
                          _: "-30%",
                          [BREAKPOINT_SUB_IMAGE_TEXT]: 0,
                        }
                      : "unset"
                  }
                  right={
                    align === "left"
                      ? {
                          _: "-30%",
                          [BREAKPOINT_SUB_IMAGE_TEXT]: 0,
                        }
                      : "unset"
                  }
                  flex={{
                    _: "0 0 80%",
                    sm: "0 0 50%",
                    [BREAKPOINT_SUB_IMAGE_TEXT]: "initial",
                  }}
                  align={align}
                  image={image}
                  imageSubText={imageSubText}
                  display={align !== "right top" ? "block" : "none"}
                />
              </ThemeSwitcher>
            </Flex>
          </Box>
          {align === "right top" && (
            <SideImage
              display={{ _: "none", xs: "block" }}
              align="right"
              image={image}
              imageSubText={imageSubText}
            />
          )}
        </Flex>
        {imageSubText && (
          <Box
            mt={5}
            display={{ _: "block", [BREAKPOINT_SUB_IMAGE_TEXT]: "none" }}
          >
            {imageSubText.map((text, idx) => {
              return (
                <Reveal key={idx}>
                  <Paragraph maxWidth={"415px"} pl={5} mb={4}>
                    {text}
                  </Paragraph>
                </Reveal>
              )
            })}
          </Box>
        )}
        {button && (
          <Flex width={"100%"} justifyContent={"center"}>
            <Button {...button} my={{ _: 6 }} />
          </Flex>
        )}
      </StandardPadding>
    </Section>
  )
}

export default ImageCards

export const query = graphql`
  fragment ImageCards on ContentfulImageAndCards {
    sectionStyling {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    infoCards {
      ...InfoCard
    }
    cardsBackgroundColor
    iconSize
    align
    imageSubText
    image {
      description
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
        aspectRatio
      }
    }
    cardsStyle
    cardsColumns
    button {
      ...Button
    }
  }
`
