import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import Select from "components/atoms/Select"

import styled from "styled-components"

import ChartCard from "components/molecules/ChartCard"

const StyledButton = styled.button`
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: opacity 0.2s ease;
`

const ChartCards = ({ chartCards, section, displayMode, isFirstSection }) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const [cardData, setCardData] = useState(null)
  const [selectorData, setSelectorData] = useState(null)

  useEffect(() => {
    let selections = []
    chartCards.map((card, idx) => {
      selections.push({ value: idx, label: card.cardTitle })
    })
    setSelectorData(selections)
  }, [])

  useEffect(() => {
    setCardData(chartCards[activeIdx])
  }, [activeIdx])

  return (
    <Section
      {...section}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding pt={section.paddingTop} pb={section.paddingBottom}>
        {displayMode === "tabs" && (
          <>
            <Flex mb={{ _: 0, lg: 6 }}>
              {chartCards.map((card, idx) => {
                return (
                  <Reveal key={idx}>
                    <Box mr={5} display={{ _: "none", lg: "block" }}>
                      <StyledButton
                        active={idx === activeIdx}
                        onClick={() => setActiveIdx(idx)}
                      >
                        {card.cardTitle}
                      </StyledButton>
                    </Box>
                  </Reveal>
                )
              })}
            </Flex>
            <Box mb={6} display={{ _: "block", lg: "none" }}>
              <Select
                selections={selectorData}
                value={selectorData && selectorData[activeIdx].value}
                onChange={e => setActiveIdx(e.target.value)}
              />
            </Box>
            <Reveal>
              <Box
              //display={active ? "block" : "none"}
              // style={{ transform: `translateY(-${100 * idx}%)` }}
              >
                <ChartCard {...cardData} />
              </Box>
            </Reveal>
            {/* {chartCards.map((card, idx) => {
              const active = idx === activeIdx
              return (
                <Reveal key={idx}>
                  <Box
                    display={active ? "block" : "none"}
                    // style={{ transform: `translateY(-${100 * idx}%)` }}
                  >
                    <ChartCard {...card} />
                  </Box>
                </Reveal>
              )
            })} */}
          </>
        )}
        {displayMode === "list" && (
          <>
            {chartCards.map((card, idx) => (
              <Reveal>
                <ChartCard key={idx} {...card} />
              </Reveal>
            ))}
          </>
        )}
      </StandardPadding>
    </Section>
  )
}

export default ChartCards

export const query = graphql`
  fragment ChartCards on ContentfulChartCards {
    entryTitle
    section {
      ...Section
    }
    chartCards {
      ...ChartCard
    }
    displayMode
  }
`
